import { DateBox } from 'devextreme-react';
import React, { Component } from 'react';
import { Calendar, DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import persian_en from "react-date-object/locales/persian_en";
import DatePicker from "react-multi-date-picker";
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

class TimePickerHHMM extends Component {
    constructor(props) {
        super(props);
        this.hhmm = props.hhmm ?? props.data?.value ?? new Date(props.value ?? undefined).toTimeString(); // props.value ?? '00:00';
        if (!this.hhmm || this.hhmm.includes('NaN'))
            this.hhmm = '00:00';

        this.date = new Date(`01/01/2023 ${this.hhmm}`);

        //     if (!(val?.length > 0))
        //     val = '00:00';
        // let hms = val?.split(':') ?? [];
        // this.date = new DateObject({
        //     date: val,
        //     format: "YYYY/MM/DD HH:mm",
        //     calendar: persian,
        //     locale: persian_fa,
        //     hour: Number((hms.length > 0 ? hms[0] : '0') ?? '0'),
        //     minute: Number((hms.length > 1 ? hms[1] : '0') ?? '0'),
        //     second: Number((hms.length > 2 ? hms[2] : '0') ?? '0'),
        // });
    }
    state = {};

    render() {
        return (
            <div className='time-picker-wrapper' key={this.props.key}>
                <DateBox {...this.props} key={this.props.key + '_datebox'} rtlEnabled={false} type='time' value={this.date}
                    displayFormat='HH:mm'
                    onValueChanged={e => {
                        //console.log(e.value);
                        this.hhmm = e.value?.toTimeString()?.substr(0, 5) ?? '00:00';
                        this.date = new Date('01/01/2023 ' + this.hhmm);
                        this.props.data?.setValue(this.hhmm);
                        //this.props.onValueChanged?.(e);
                        this.props.onHhMmChanged?.(this.hhmm);
                    }}
                />
            </div>
        );
    }
}

export default TimePickerHHMM;