import React from 'react';
import UniPage from './UniPage';
// import UsersManager from './user/UsersManager';
// import Questionnare from './design-ques/Questionnare';
// import LikertScale from './design-ques/LikertScale';
// import FillQuestionnare from './fill-ques/FillQuestionnare';
// import QuestionType from './design-ques/QuestionType';
import { isMobile } from 'react-device-detect';
import ProductModel from './hurly/ProductModel';
import ProductModels from './hurly/ProductModels';
import Properties from './hurly/Properties';

const stl = {
    marginTop: isMobile ? '0.5rem' : '1rem',
    width: 'auto', // isMobile ? 'auto' : '70%',
    padding: isMobile ? '0.5rem' : '1rem',
    background: 'whitesmoke',
    borderRadius: isMobile ? '0.5rem' : '1rem'
};

class CommandManager {
    static key = 1;
    static routesDic = [
        { key: 'ProductModel', component: prp => <ProductModel style={stl} {...prp} /> },
        { key: 'ProductModels', component: prp => <ProductModels style={stl} {...prp} /> },
        { key: 'Properties', component: prp => <Properties style={stl} {...prp} /> },
        // { key: 'Questionnare', component: prp => <Questionnare style={stl} {...prp} /> },
        // { key: 'FillQuestionnare', component: prp => <FillQuestionnare style={stl} {...prp} /> },
        // { key: 'QuestionType', component: prp => <QuestionType style={stl} showDeleteButton showApplyButton {...prp} /> },
        // { key: 'LikertScale', component: prp => <LikertScale style={stl} showDeleteButton showApplyButton {...prp} /> },
    ];

    static ComponentOf(url, item, additionalProps) {
        return (
            <UniPage ref={el => window.uniPage = el}
                //key={'comng_' + ++CommandManager.key}
                menuItem={item ?? { url }}
                additionalProps={additionalProps}
            />
        );
    }

    static process(url, item, additionalProps, whenDone) {
        window.setInnerBody(CommandManager.ComponentOf(url, item, additionalProps), whenDone);
    }
}

export { stl };
export default CommandManager;
