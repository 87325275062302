import React, { Component } from 'react';
import notify from 'devextreme/ui/notify';
//import Home from './components/Home';
import { apiGet, apiGetP, deleteCookie, getCookie, setCookie, updateTree } from './GanjiTools';
//import Logon from './components/logon/Logon';
import 'devextreme/dist/css/dx.common.css';
import SideDivider from './components/layout/SideDivider';
//import localforage from 'localforage';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//import { stl } from './components/CommandManager';
import UniPage from './components/UniPage';
import PropSheets from './components/rbi/PropSheets';
import RbiHome from './components/rbi/RbiHome';
import { TreeList } from 'devextreme-react';
// import 'devextreme/data/odata/store';
import AspNetData from 'devextreme-aspnet-data-nojquery';
import { HeaderFilter, RemoteOperations, SearchPanel } from 'devextreme-react/tree-list';
import PSM_Layout from './components/psm/PSM_Layout';
import CustomerHome from './components/hurly/CustomerHome';
import Logon from './components/logon/Logon';
import AdminHome from './components/hurly/AdminHome';
import ProductsTree from './components/hurly/ProductsTree';
import Properties from './components/hurly/Properties';
import ProductModel from './components/hurly/ProductModel';

const Theme1 = React.lazy(() => import("./components/theme/Theme1"));
const Theme2 = React.lazy(() => import("./components/theme/Theme2"));
const Theme3 = React.lazy(() => import("./components/theme/Theme3"));
const Theme4 = React.lazy(() => import("./components/theme/Theme4"));
const Theme5 = React.lazy(() => import("./components/theme/Theme5"));
const Theme6 = React.lazy(() => import("./components/theme/Theme6"));

const historySize = 10;
const defaultTheme = '1';

export default class App extends Component {
    static globalCounter = 1;
    static productToView = 0;

    constructor(prp) {
        super(prp);

        console.log('location = ', document.location?.href);
        let uu = document.location?.href?.split('/model/');
        if (uu.length < 2)
            uu = document.location?.href?.split('?model=');

        window.productToView = uu?.length > 1 ? uu[1] : '';

        this.state = {
            body: window.productToView > 0 ? <></> : <></>,
            bodyKey: 1,
            innerBodyKey: 1,
            serverConnectError: 'wait',
            appKey: 1,
            loaded: false,
            sheetKey: 1,
            assetsTreeKey: 1,
            manageKey: 1,
        };

        window.bodyHistory = [];

        window.setInnerBody = (component, whenDone, thenSaveToHistory = false) => {
            window.uniPage?.saveStates?.();
            SideDivider.innerBody = component;

            if (thenSaveToHistory) {
                while (window.bodyHistory.length >= historySize)
                    window.bodyHistory = window.bodyHistory.splice(1);

                window.bodyHistory.push(component);
                window.historyIndex = window.bodyHistory.length - 1;
            }

            this.setState({ innerBodyKey: this.state.innerBodyKey + 1 }, whenDone);
            //SideDivider.innerBodyRef?.forceUpdate?.();
        };

        window.goForward = whenDone => {
            if (window.historyIndex < window.bodyHistory.length - 1)
                window.setInnerBody(window.bodyHistory[++window.historyIndex], whenDone, false);
        }

        window.goBack = whenDone => {
            if (window.historyIndex > 0)
                window.setInnerBody(window.bodyHistory[--window.historyIndex], whenDone, false);
        }

        window.canGo = () => ({ back: window.historyIndex > 0, forward: window.historyIndex < window.bodyHistory.length - 1 });

        window.setContentBody = (component, whenDone, thenSaveToHistory = true) => {
            window.setInnerBody(component, whenDone, thenSaveToHistory);
            //this.setState({ body: component, bodyKey: this.state.bodyKey + 1 }, whenDone);
        };

        window.refreshPage = whenDone => {
            window.appComponent?.setState({ bodyKey: window.appComponent.state.bodyKey + 1 }, whenDone);
        }

        window.refreshSideMenu = whenDone => {
            window.masterLayoutComponent?.setState({ sideKey: ++App.globalCounter }, whenDone);
        }

        //G.goToRoute = (url, whenDone) => G.setContentBody(routes[url], whenDone);

        const logoff = whenDone => {
            deleteCookie('hurly_logonkey');
            deleteCookie('hurly_user_fullname');
            setCookie('hurly_userid', '0');
            window.user = null;
            apiGet('/api/logon/logoff', whenDone, whenDone);
        }

        window.refresh = whenDone => this.setState({ bodyKey: this.state.bodyKey + 1 }, whenDone);
        window.onServerFailed = serverConnectError => this.setState({ serverConnectError, bodyKey: this.state.bodyKey + 1 });

        window.logout = whenDone => {
            console.log('logout.');
            // if (window.saverFunction)
            //   setState({ showConfirmToSave: true });
            // else
            logoff(() => window.refresh(whenDone));
        };

        window.notif = msg => {
            notify({
                message: msg, //Array.isArray(msg) ? msg.reduce((a, b) => a + ' ' + b) : msg,
                // height: 45,
                width: 350,
                // minWidth: 150,
                type: 'info',
                displayTime: 5000,
                rtlEnabled: true
            }, {
                position: 'bottom left',
                direction: 'up-stack',
            });
            console.log('>- NOTIFY: ', msg);
            //Array.isArray(msg) ? console.log('>- NOTIFY: ', ...msg) : console.log('>- NOTIFY: ', msg);
        };

        //G.setFloatMessages = floatMessageItems => this.props.element?.setState({ floatMessageItems, floatMessagesKeyCounter: state.floatMessagesKeyCounter + 1 });
    }

    static checkConnection = getConnectionError => {
        apiGet('/api/logon/checkConnection',
            ({ resp, user }) => getConnectionError(resp === 'ok' ? '' : 'not_ok_resp_' + resp),
            err => getConnectionError('bad_resp_' + err)
        );
    }

    setUserP = user => new Promise((resolve, reject) => {
        window.user = user;
        //console.log('app is mounted. userId= ', id);

        apiGetP('/api/logon/loggedUser')
            .catch(reject) //window.logout)
            .then(u => {
                if (u?.id !== window.user?.id || !(u.id > 0)) {
                    reject?.('invalid user!');
                    window.logout();
                }
                else {
                    user.fullname = u.fullname;
                    if (!(window.productToView > 0)) {
                        resolve?.(u);
                        return;
                    }

                    window.setInnerBody(
                        <UniPage ref={el => window.uniPage = el}
                            //key={'comng_' + ++CommandManager.key}
                            menuItem={{ url: `component:fillquestionnare|props:{"id":${window.productToView}}` }}
                            additionalProps={{ rows: undefined }}
                        />,
                        () => resolve?.(u)
                    );
                }
            });
    });

    componentDidMount() {
        window.appComponent = this;

        //return;
        // App.checkConnection(err => {
        //     if (err) {
        //         this.setState({ loaded: true, serverConnectError: err, bodyKey: this.state.bodyKey + 1 });
        //         return;
        //     }

        //     this.setState({ serverConnectError: '' }, () => {
        //         library.add(fas);

        //         window.user = null;
        //         let id = Number(getCookie('riscovery_userid') ?? '0') ?? 0;
        //         let fullname = id !== 0 ? getCookie('riscovery_user_fullname') : '';
        //         let logonkey = id !== 0 ? getCookie('riscovery_logonkey') : '';

        //         // try {
        //         //     localforage.config({
        //         //         driver: localforage.WEBSQL, // Force WebSQL; same as using setDriver()
        //         //         name: 'polling',
        //         //         version: 1.0,
        //         //         size: 100000000, // Size of database, in bytes. WebSQL-only for now.
        //         //         storeName: 'keyvaluepairs', // Should be alphanumeric, with underscores.
        //         //         description: 'polling localforage'
        //         //     });
        //         // } catch { }

        //         if (!(logonkey?.length > 0)) {
        //             if (window.productToView > 0)
        //                 apiGetP('/api/ques/fill/' + window.productToView)
        //                     .catch(err => window.notif('پرسشنامه موردنظر یافت نشد', err))
        //                     .then(({ questionnare, user, error }) => {
        //                         if (error?.length > 0) {
        //                             window.notif('پرسشنامه موردنظر یافت نشد');
        //                             window.logout();
        //                         }
        //                         else {
        //                             this.setUserP(user)
        //                                 .catch(window.logout)
        //                                 .then(u => this.setState({ loaded: true }, () => console.log(u)));
        //                         }
        //                     });
        //             else {
        //                 window.logout();
        //                 return;
        //             }
        //         }
        //         else
        //             this.setUserP({ id, fullname, logonkey }).catch(window.notif).then();

        //         // false && apiPostNoBody('/api/logon/user/' + id,
        //         //   user => {
        //         //     window.user = !user ? null : { ...user, logonkey };
        //         //     this.forceUpdate();
        //         //   }
        //         // );
        //     });
        // });
    }

    renderTheme = () => (
        <React.Suspense fallback={<div>بارگذاری تم...</div>}>
            {
                window.theme === "1" ? <Theme1 /> :
                    window.theme === "2" ? <Theme2 /> :
                        window.theme === "3" ? <Theme3 /> :
                            window.theme === "4" ? <Theme4 /> :
                                window.theme === "5" ? <Theme5 /> :
                                    window.theme === "6" ? <Theme6 /> :
                                        <></>
            }
        </React.Suspense>
    );

    render() {
        window.theme = localStorage.getItem("rbi_nap") ?? defaultTheme;
        window.config.THEME.color1 = window.theme === "1" ? 'whitesmoke' : window.theme === "2" ? 'rgb(0,0,30)' : undefined;

        // let ds = AspNetData.createStore({
        //     key: 'id',
        //     loadUrl: `https://localhost:7013/api/rbi/assets`,
        //     // insertUrl: `${url}/InsertTask`,
        //     // updateUrl: `${url}/UpdateTask`,
        //     // deleteUrl: `${url}/DeleteTask`,
        //     onBeforeSend(method, ajaxOptions) {
        //         ajaxOptions.xhrFields = { withCredentials: true };
        //     },
        // });

        return (
            <>
                {this.renderTheme()}

                {window.productToView?.length > 0 ?
                    <ProductModel serial={window.productToView} />
                    :
                    this.state.user?.id > 0
                        ?
                        <AdminHome key={'hurly_home_' + this.state.innerBodyKey} />
                        :
                        <Logon onSuccessLogon={user => this.setState({ user })}
                        />
                }
            </>
        );

        /* <HurlyHome key={'sa_' + this.state.manageKey} 
        // hid={this.state.hid ?? 0}
        // dfids={this.state.dfids ?? []}
        // onSelected={({ hid, component, dfids }) => this.setState({ hid, component, dfids, sheetKey: this.state.sheetKey + 1 })}
        // onNewAssetAdded={this.onNewAssetAdded}
        // onDfidsChanges={this.onDfidsChanges}
        />*/
        // return (
        //     this.state.serverConnectError === 'wait' ? <div>...</div>
        //         : this.state.serverConnectError ?
        //             <div style={{ padding: '4rem', color: 'red', fontSize: '1.5rem'/*, fontFamily: 'IRANSansX'*/ }}>
        //                 اتصال به سرور با مشکل مواجه شده است
        //             </div>
        //             : window.user?.id > 0 ? // && window.productToView > 0 ?
        //                 <>
        //                     {this.renderTheme()}
        //                     <Home key={'home' + this.state.bodyKey} bodyKey={this.state.innerBodyKey}
        //                         body={this.state.body}
        //                     />
        //                 </>
        //                 : !(window.user?.id > 0) && window.productToView > 0 ?
        //                     this.state.loaded &&
        //                     <div style={{
        //                         padding: '1rem',
        //                         display: 'flex', alignItems: 'center', justifyContent: 'center',
        //                         width: '100vw', height: '100vh',
        //                     }}>
        //                         <div style={{ color: '#777777', fontWeight: '900', fontSize: '2rem' }}> با تشکر </div>
        //                     </div>
        //                     :
        //                     <>
        //                         {this.renderTheme()}
        //                         <Logon //allowGuestLogon
        //                             onSuccessLogon={user => {
        //                                 //window.user = user; 
        //                                 this.setUserP(user)
        //                                     .catch(err => window.notif('ورود نامعتبر: ', err))
        //                                     .then(() => this.forceUpdate());
        //                                 //window.setInnerBody(window.user?.isadmin === true ? <AdminHomePage /> : <div>AAAAAAA</div>);
        //                                 //window.setInnerBody(<AdminHomePage />);
        //                                 //this.forceUpdate();
        //                             }}
        //                         />
        //                     </>
        // );
        // let orig = {
        //     name: 'ali',
        //     age: 23,
        //     colors: {
        //         hair: 'black',
        //         eye: 'brown',
        //         body: {
        //             front: 'light',
        //             back: 'gray'
        //         }
        //     }
        // };
        // let chng = {
        //     age: 33,
        //     job: 'student',
        //     sports: {
        //         first: 'football',
        //         second: 'basketball'
        //     },
        //     colors: {
        //         eye: 'olive',
        //         hand: 'yellow',
        //         body: {
        //             back: 'khaki'
        //         }
        //     }
        // };

        // updateTree(orig, chng);
        // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');
        // console.log(orig);

        //console.log('app is rendered. userId= ', window.user?.id);
        //  {this.state.theme === 1 ? <Theme1 />
        //  : /*this.state.theme === 2 ?*/ <Theme2 />
        //}   
    }
}
