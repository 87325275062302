import { Button } from 'devextreme-react';
import React, { Component } from 'react';

class Footer extends Component {
    constructor(props) {
        super(props);
    }
    state = {};

    render() {
        return (
            this.props.height > 0 ?
                <>
                    <img src='/images/bipc_logo_small.png' height='100%' />

                    <span style={{ padding: '0 1rem' }}>
                        Polling Manager <span style={{ width: '3rem' }}>&nbsp;</span> پتروشیمی ----
                    </span>

                    <span style={{ position: 'fixed', left: '5px', display: 'inline-flex', alignItems: 'center' }}>
                        <span style={{ cursor: 'pointer' }} onClick={this.onUserIconClick}>{window.user?.fullname}</span>
                        <Button rtlEnabled={true} icon='user' height='100%' width='3rem' stylingMode='text'
                            onClick={this.props.onUserIconClick}
                        />
                    </span>
                </>
                : <></>
        );
    }
}

export default Footer;