import React, { Component } from 'react';
//import './Spinner0.scss';
// import './Spinner.css';
//import jamFlower from '../jam-flower.png';
import './Loading.css';
import logo from './macan-logo.png';

class Spinner extends Component {

    render() {
        let size = this.props.size >= 3 ? 'large' : this.props.size <= 1 ? 'small' : 'medium';

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0',
                padding: '1rem', 
                height: '100%',
                width: '100%',
                backgroundColor: 'transparent',
            }}
            >
                <div className={`${size}-loading-container`}>
                    <div className={`${size}-loader-container`}>
                        <img src={logo} />
                        {this.props.size == 3 &&
                            <div className={`${size}-loader ${size}-load-1`}></div>
                        }
                        <div className={`${size}-loader ${size}-load-2`}></div>
                        <div className={`${size}-loader ${size}-load-3`}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Spinner;

//     render() {
//         return (
//             <Loading size={this.props.size ?? 2} />
//             // <div style={{ padding: '1rem' }}>
//             //     <div className='custom-loader' style={{ margin: 'auto' }}></div>
//             // </div>
//         );
//         /*
//                 return (
//                     <div style={{ padding: '3rem', width: '90vw', display: 'flex', justifyContent: 'center' }}>
//                         {}
//                         <img style={{ width: '65px', height: '65px', opacity: '50%' }} src={jamFlower} className='rotate' />
//                     </div>
        
//                 );
//                 */
//         /* //     <Oval
//             //         ariaLabel="loading-indicator"
//             //         height={80}
//             //         width={80}
//             //         strokeWidth={5}
//             //         strokeWidthSecondary={5}
//             //         color="grey"
//             //         secondaryColor="white"
//             //     /> */
//     }
// }

