import React, { Component } from 'react';

class Space extends Component {
    state = {};

    render() {
        let pw = this.props.width ?? '1rem';
        let w = Number.isInteger(pw) ? pw + 'px' : pw;

        return (
            <div style={{ width: w, display: 'inline-flex' }}>&nbsp;</div>
        );
    }
}

export default Space;