import React, { Component } from 'react';
import MasterLayout from '../layout/MasterLayout';

class AdminHome extends Component {
    state = {
        body: <></>,
        bodyKey: 1,
    }
    render() {
        return (
            <MasterLayout key={'body' + this.state.bodyKey} //noTopMenu
                body={this.state.body} bodyKey={this.props.bodyKey}
            //headerExtraItems={[<p>Hello</p>]}
            />
        );
    }
}

export default AdminHome;