import { Button, Popup } from 'devextreme-react';
import React, { Component } from 'react';
import Inline from './Inline';
import Space from './Space';

class UniPopup extends Component {
    onOk = () => {
        //this.setState({ visible: false });
        this.props.onClose?.();
        this.props.onOk?.(this.props.onNeedResult?.(this.state) ?? this.state);
    }

    onCancel = () => {
        this.props.onClose?.();
        this.props.onCancel?.();
        //this.setState({ visible: false });
    }

    state = {
        //visible: this.props.visible > 0,
        commands: this.props.commands ??
            (this.props.hideOkButton ?
                [
                    { text: this.props.cancelText ?? 'بستن', onClick: this.onCancel, icon: 'close' },
                ] :
                [
                    { text: this.props.okText ?? 'قبول', onClick: this.onOk, icon: 'check' },
                    { text: this.props.cancelText ?? 'رد', onClick: this.onCancel, icon: 'close' },
                ]
            ),
    };


    render() {
        let n = 0;
        return (
            //this.state.visible === true &&
            <Popup //key={'ppp' + this.props.visible + '_' + this.state.visible}
                visible={this.props.visible} rtlEnabled={true} resizeEnabled={true}
                onShown={this.props.onShown} onShowing={this.props.onShowing}
                title={this.props.title}
                onHiding={this.props.onClose}
                width={this.props.width ?? 'fit-content'} height={this.props.height ?? 'fit-content'}
                shading={true} shadingColor='rgba(0,0,0,0.5)'
                onTitleRendered={e => e.element.style.background = 'red'}
                {...this.props.xprops}
            >
                <div style={{ padding: '1rem' }} className='ka-unipopup-content-container'>
                    {this.props.children}
                </div>

                {this.state.commands?.length > 0 &&
                    <Inline spacing='5px' style={{ marginTop: '1rem', marginRight: '5rem' }}>
                        {this.state.commands.map(cmd =>
                            <Button key={'cmd_btn_' + n++} text={cmd.text} icon={cmd.icon}
                                onClick={e => cmd.onClick === 'close' || cmd.onClick === 'cancel' ? this.onCancel() : cmd.onClick?.()}
                                width='10rem'
                            />
                        )}
                    </Inline>
                }
            </Popup>
        );
    }
}

export default UniPopup;