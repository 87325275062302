import React, { Component } from 'react';
import { UniGrid } from '../UniGrid';
import { CheckBox } from 'devextreme-react';
import Space from '../Space';
import { apiPost, apiPostP } from '../../GanjiTools';

class Properties extends Component {
    state = {
        properties: this.props.properties
    };

    onRelatedTableNamesChanged = (row, isAdded, tableName) => {
        console.log(row, isAdded, tableName);
        row.tablenames = isAdded ? `${row.tablenames}/${tableName}/` : row.tablenames.replaceAll(`/${tableName}/`, '/');
        row.tablenames = row.tablenames?.replaceAll('//', '/') ?? '/';
        apiPostP('/api/uni/saveRow/property', { id: row.id, tableNames: row.tablenames })
            .catch(window.notif)
            .then(console.log);
    }

    renderRelatedTableNamesCell = r => {
        let tables = `/${r.data.tablenames}/`;

        return (
            <div>
                <span>
                    <CheckBox text='نوع کالا' value={tables.includes('/producttype/')}
                        //onValueChanged={e => r.data.tablenames = e.value ? r.data.tablenames + 'producttype/' : r.data.tablenames.replaceAll('/producttype/', '/')}
                        onValueChanged={e => this.onRelatedTableNamesChanged(r.data, e.value, 'producttype')}
                    />
                </span>
                <span>
                    <Space width='2em' />
                </span>
                <span>
                    <CheckBox text='مدل کالا' value={tables.includes('/productmodel/')}
                        //onValueChanged={e => r.data.tablenames = e.value ? r.data.tablenames + 'productmodel/' : r.data.tablenames.replaceAll('/productmodel/', '/')}
                        onValueChanged={e => this.onRelatedTableNamesChanged(r.data, e.value, 'productmodel')}
                    />
                </span>
            </div>
        );
    }

    render() {
        return (
            <UniGrid table='property' title='خصوصیات' //editingMode='row'
                hideFields={['tableNames', 'isDeleted', 'lookupJsn']}
                customColumns={[
                    {
                        caption: 'مربوط به', cellRender: this.renderRelatedTableNamesCell
                    }
                ]}
            />
        );
    }
}

export default Properties;