import React, { Component } from 'react';
import { Calendar, DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import persian_en from "react-date-object/locales/persian_en";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Url } from 'devextreme-react/chart';
import { Button, Popup } from 'devextreme-react';
import { Tarikh } from '../GanjiTools';
import './theme/additional.css';

class TarikhPicker extends Component {
    constructor(props) {
        super(props);

        let val = props.data?.value ?? props.value;
        this.date = new DateObject({
            date: val,
            format: "YYYY/MM/DD",
            calendar: persian,
            locale: persian_fa,
            // hour: Number((hms.length > 0 ? hms[0] : '0') ?? '0'),
            // minute: Number((hms.length > 1 ? hms[1] : '0') ?? '0'),
            // second: Number((hms.length > 2 ? hms[2] : '0') ?? '0'),
        });

        this.state = {
            value: val, // this.date, // props.data?.value ?? this.props.value
        };
    }

    render0() {
        return (
            <div className='ka-tarikh-picker' style={{ direction: "rtl" }}>
                <DatePicker
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                />
            </div>
        )
    }

    render() {
        //console.log(' ---- tt -----', this.state.value, this.props.value);
        return (
            <div key={'tp' + this.state.value}>
                <Popup
                    visible={this.state.pickerVisible}
                    //onHiding={e => console.log('hiding ', e)}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={false}
                    //title="تاریخ"
                    //container=".dx-viewport"
                    width='fit-content'
                    height='fit-content'
                    style={{ padding: '0' }}
                >
                    <Calendar
                        minDate={this.props.min}
                        maxDate={this.props.max}
                        value={this.state.value}
                        // plugins={[
                        //     <TimePicker position="bottom" hideSeconds //onSubmit={e => console.log(e)}
                        //     />
                        // ]}
                        //format='yyyy/MM/dd HH:mm'
                        calendar={persian}
                        locale={persian_fa}
                        onChange={e => {
                            this.tempValue = new Tarikh(e.toDate()).getString();// + ' ' + e.hour + ':' + e.minute
                            this.date = e.toDate();
                        }}
                        style={{ margin: '0' }}
                    />
                    <Button width={'50%'} onClick={e => this.setState({ value: this.tempValue, pickerVisible: false },
                        () => {
                            (this.props.onValueChanged ?? this.props.onChanged)?.(this.tempValue, new DateObject({ date: this.date }));
                            this.props.data?.setValue(this.tempValue);
                        })}
                    >
                        قبول
                    </Button>
                    <Button width={'50%'} onClick={e => this.setState({ pickerVisible: false })}>
                        رد
                    </Button>
                </Popup>
                <TextBox rtlEnabled={false} // key={this.state.value} text={this.state.value}
                    key={this.state.value ?? this.props.value ?? this.props.data?.value}
                    text={this.state.value ?? this.props.value ?? this.props.data?.value}
                    readOnly={this.props.readOnly} //mask='0000/00/00' 
                > {/*defaultValue={this.props.data.value}*/}
                    {this.props.readOnly != true &&
                        <TextBoxButton name='tarikh' location='after' className='dx-icon-font'
                            options={{
                                icon: 'event',
                                onClick: e => this.setState({ pickerVisible: true })
                            }} />
                    }
                </TextBox>
            </div>
        );
    }
}

export default TarikhPicker;