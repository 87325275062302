import React, { Component } from 'react';
import Space from './Space';

class Inline extends Component {
    state = {};


    render() {
        let fai = this.props.farItems?.length > 0 ? this.props.farItems : this.props.farItems ? [this.props.farItems] : [];
        let rtl = this.props.rtlEnabled !== false;

        return (
            <div className={this.props.className}
                style={{
                    display: 'flex', alignItems: 'center',
                    justifyContent: this.props.center ? 'center' : undefined, // horz
                    height: this.props.height,
                    width: this.props.width,
                    padding: this.props.padding,
                    margin: this.props.margin,
                    position: 'relative',
                    ...this.props.style,
                }}
            >
                {this.props.children?.length > 0 ?
                    this.props.children.map((x, index) => (
                        !x ? <></> :
                            this.props.spacing && index > 0
                                ? <><Space width={this.props.spacing} />{x}</>
                                : x
                    ))
                    : this.props.children?.length === 1 ?
                        this.props.children[0]
                        : <></>
                }

                {fai.length > 1 ?
                    <Inline style={{ position: 'absolute', left: rtl ? '5px' : undefined, right: rtl ? undefined : '5px', height: '100%' }}
                        spacing={this.props.farItemsSpacing ?? this.props.spacing}
                    >
                        {fai}
                    </Inline>
                    : fai.length === 1 ?
                        <div style={{ position: 'absolute', left: rtl ? '5px' : undefined, right: rtl ? undefined : '5px', height: '100%' }}>
                            {fai[0]}
                        </div>
                        : <></>
                }

            </div>
        );
    }
}

export default Inline;