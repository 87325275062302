import { DataGrid, Popup } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import React, { Component } from 'react';
import AspNetData from 'devextreme-aspnet-data-nojquery';
import { Column, GroupItem, Grouping } from 'devextreme-react/cjs/data-grid';
import { UniGrid } from '../UniGrid';
import UrlImage from '../UrlImage';
import UniPopup from '../UniPopup';
import PropertyValues from './PropertyValues';
import Uploader from '../Uploader';

class ProductModels extends Component {
    state = {
    };

    ds = AspNetData.createStore({
        key: 'id',
        loadUrl: `${window.config.API_PREFIX}/api/market/models`,
        // insertUrl: `${url}/InsertTask`,
        // updateUrl: `${url}/UpdateTask`,
        // deleteUrl: `${url}/DeleteTask`,
        onBeforeSend(method, ajaxOptions) {
            ajaxOptions.xhrFields = { withCredentials: true };
        },
    });

    onUpoadImage = (focusedTable, rowId) => {
        this.setState({ focusedTable, uploadPopVisible: true });
    }

    render() {
        return (
            <>
                <div>
                    <UniGrid table='producttype' // url={`/api/market/models0`}
                        title='انواع محصول'
                        rtlEnabled
                        modifyFields={[
                            { name: 'name', caption: 'عنوان' },
                        ]}
                        hideFields={['isdeleted', 'tempCol', 'exCol']}
                        onFocusedRowChanged={focusedType => this.setState({ focusedType, focusedTable: 'producttype' })}
                        buttonColumns={[
                            {
                                name: 'properties', caption: 'خصوصیات', icon: 'detailslayout', locateAtBefore: true, width: 'auto', minWidth: '4rem',
                                hint: 'خصوصیات', onClick: focusedType => this.setState({ focusedType, propsPopVisible: true, focusedTable: 'producttype' })
                            }
                        ]}
                        customColumns={[
                            {
                                caption: 'تصویر',
                                width: 100,
                                locateAtBefore: true,
                                cellRender: c => (c.data?.id > 0 &&
                                    <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                                        onClick={e => this.onUpoadImage('producttype', c.data.id)}
                                    >
                                        <img src={`${window.config.API_PREFIX}/api/market/image/productType_${c.data?.id}`}
                                            onError={e => e.target.src = '/images/no-image.png'}
                                            alt='' height='90%'
                                        />
                                    </div>
                                )
                            }
                        ]}
                    />
                    <br />

                    <UniGrid table='productmodel' // url={`/api/market/models0`}
                        title='عناوین مدل ها'
                        rtlEnabled groupBy='fk_producttype'
                        modifyFields={[
                            // { name: 'id', visible: false },
                            { name: 'name', caption: 'عنوان مدل' },
                            { name: 'fk_producttype', caption: 'نوع' },
                            // /{ name: 'tempcol', caption: 'تصویر', width: 100 }
                        ]}
                        hideFields={['isdeleted', 'tempCol', 'exCol']}
                        onFocusedRowChanged={focusedModel => this.setState({ focusedModel, focusedTable: 'productmodel' })}

                        customColumns={[
                            {
                                caption: 'تصویر',
                                width: 100,
                                locateAtBefore: true,
                                cellRender: c => (c.data?.id > 0 &&
                                    <div style={{
                                        height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer',
                                    }}
                                        onClick={e => this.onUpoadImage('productmodel', c.data.id)}
                                    >
                                        <img src={`${window.config.API_PREFIX}/api/market/image/productModel_${c.data?.id}`}
                                            alt='' height='90%' onError={e => e.target.src = '/images/no-image.png'}
                                        />
                                    </div>
                                )
                            }
                        ]}

                        buttonColumns={[
                            {
                                name: 'properties', caption: 'خصوصیات', icon: 'detailslayout', locateAtBefore: true, width: 'auto', minWidth: '4rem',
                                hint: 'خصوصیات', onClick: focusedModel => this.setState({ focusedModel, propsPopVisible: true, focusedTable: 'productmodel' })
                            }
                        ]}
                    />
                </div>

                {this.state.focusedType?.id > 0 && this.state.focusedTable?.length > 0 &&
                    <Popup title={'خصوصیات ' + (this.state.focusedTable === 'producttype' ? 'نوعی کالا' : 'مدل کالا')} visible={this.state.propsPopVisible} rtlEnabled
                        onHidden={() => this.setState({ propsPopVisible: false })}
                    >
                        <PropertyValues key={`pv_${this.state.focusedModel?.id}_${this.state.focusedType?.id}`} table={this.state.focusedTable}
                            ownerId={(this.state.focusedTable === 'producttype' ? this.state.focusedType : this.state.focusedModel)?.id}
                        />
                    </Popup>
                }

                <Popup title='انتخاب تصویر' rtlEnabled key={`uplpop${this.state.focusedModel?.id}_${this.state.focusedType?.id}`}
                    visible={this.state.uploadPopVisible} width='30rem' height='auto'
                    onHidden={() => this.setState({ uploadPopVisible: false })}
                >
                    {this.state.focusedTable?.length > 0 &&
                        <Uploader accept='image/*' subPath={`images/${this.state.focusedTable}/${this.state.focusedTable === 'producttype' ? this.state.focusedType?.id : this.state.focusedModel?.id}`}
                            nameOnServer='[counter]'
                            onUploaded={(error, url) => console.log('upload error-url= ', error, url)}
                            onError={console.log}
                        />
                    }
                </Popup>
            </>
        );
    }
}

export default ProductModels;