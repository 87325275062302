import React, { Component } from 'react';
import { apiGetP } from '../../GanjiTools';
import { Gallery } from 'devextreme-react/gallery';
import './ProductModel.scss';
import Spinner from '../utils/Spinner';
import { Button } from 'devextreme-react';
import PropertyValues from './PropertyValues';

class ProductModel extends Component {
    state = {
        galKey: 1,
        id: this.props.id,
    };

    loadModelP = () => new Promise((resolve, reject) => {
        let id = this.state.id ?? 0;
        let serial = this.props.serial ?? ''

        if (!(id > 0 || serial?.length > 0)) {
            reject?.('Invalid model id:' + id);
            return;
        }
        apiGetP(`/api/market/model?id=${id}&serial=${serial}`).catch(reject).then(resolve);
    });

    componentDidMount() {
        this.customerMode = window.productToView?.length > 0;

        this.setState({ loading: true }, () => {
            this.loadModelP()
                .catch(window.notif)
                .then(res => {
                    res ??= { error: 'No response.' };
                    if (res.error?.length > 0) {
                        this.state.model = {};
                        //this.setState({ model: {} });
                        return;
                    }

                    let images = !(res.images?.length > 0) ? [] : res.images.map(x => window.config.API_PREFIX + x);  //@@${Math.round(Math.random() * 10000)}`);

                    this.state.model = res.result;
                    this.state.id = this.state.model?.id;
                    this.state.images = images;
                    this.state.galKey++;
                    //this.setState({ model: res.result, images, galKey: this.state.galKey + 1 }, () => console.log(this.state.images));
                })
                .finally(() => this.setState({ loading: false }));//, () => this.gallery?.instance?._refresh()));
        });
    }

    render() {
        return (
            !(this.state.id > 0) || !this.state.model ? <p>...</p> :
                this.state.loading ? <Spinner /> :
                    <>
                        <div dir='ltr' key={'dgal' + this.state.galKey}
                            style={{
                                height: '100%', display: 'flex', justifyContent: 'center',
                                alignItems: 'center', margin: 'auto', padding: '1rem',
                            }}>
                            {this.state.images?.length > 0 ?
                                <Gallery key={'gal' + this.state.galKey} id='gallery' ref={el => this.gallery = el} height='auto'
                                    dataSource={this.state.images} showIndicator showNavButtons onItemClick={console.log} //stretchImages={false}
                                    slideshowDelay={5000} loop
                                />
                                :
                                <img src='/images/no-image.png' alt='' />
                            }
                        </div>

                        {/* {this.state.images?.map(x => <p>{x}</p>)} */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '2rem', fontSize: '18px', fontWeight: 'bold' }}>{this.state.model.name}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{this.state.model.descr}</div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{this.state.model.comment}</div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <PropertyValues table='productmodel' ownerId={this.state.id} width='50rem'
                                customerMode={this.customerMode}
                            />
                        </div>
                    </>
        );

    }
}
/*
rtlEnabled={!this.props.ltr}
width={this.props.width} height={this.props.height}
keyExpr={key}
headerFilter={this.props.showHeaderFilter !== false}
showBorders={this.props.showBorders !== false}
allowUpdating={this.props.allowDeleting !== false && this.props.readOnly !== true}
allowAdding={this.props.allowAdding !== false && this.props.readOnly !== true}
allowDeleting={this.props.allowDeleting !== false && this.props.readOnly !== true}
columnAutoWidth={this.props.columnAutoWidth !== false}
selectedRowKeys={this.props.selectedKeys}
onSelectedRowKeysChange={s => {
    this.selectedKeys = s;
    this.props.onSelectedKeysChanged?.(s);
}}
showColumnLines={true}
showRowLines={true}
hoverStateEnabled={true}
rowAlternationEnabled={this.props.rowAlternationEnabled}
*/

export default ProductModel;