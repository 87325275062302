import React, { Component } from 'react';
import { apiGet } from '../../GanjiTools';
import { Accordion, ContextMenu, List, Menu } from 'devextreme-react';
import MasterLayout from './MasterLayout';
import './MasterLayout.css';
import CommandManager from '../CommandManager';
import Badge from '../utils/Badge';
import Globals from '../Globals';
import { isMobile } from 'react-device-detect';

//import '../../../src/additional.css';

class SideMenu extends Component {
    state = {
        collapsed: this.props.collapsed,
        selectedIndex: window.accoSelectedIndex ?? 0,
    };

    accoSubItemStyle = { padding: '0 5px', overflow: 'hidden', fontSize: '0.8rem' };
    accoTitleStyle = { fontSize: '0.9rem', fontWeight: '700', marginRight: '10px' };

    static accoSelectedIndex = 0;

    assignCommand = menuItem => {
        menuItem.url = menuItem.cmd ?? menuItem.path ?? menuItem.component ?? menuItem.url;

        if (menuItem.items?.length > 0)
            for (let sub of menuItem.items)
                this.assignCommand(sub);
        else
            menuItem.onClick = e => {
                //console.log('menu item clicked ', menuItem, e);
                this.props.onItemClick?.(menuItem);
            }
    }

    componentDidMount() {
        apiGet(`/api/command/sideMenu/${window.user?.id ?? 0}`, //'/api/menu/items', /*@@*/
            res => {
                res ??= { error: 'No response.' };
                if (res.error) {
                    console.log('error loading side menu items:', res.error);
                    return;
                }
                let items = res?.list;
                console.log('top menu items: ', items);
                if (items?.length > 0)
                    items.forEach(mi => this.assignCommand(mi));

                SideMenu.targetsList = res.list;
                this.setState({ items, loading: false });
            }
        );
    }

    onClickSubMenu = menuItem => {
        if (this.props.onClickSubMenu)
            this.props.onClickSubMenu(menuItem);
        else if (Globals.onClickSideSubMenu)
            Globals.onClickSideSubMenu(menuItem);
        else {
            menuItem.url = menuItem.cmd ?? menuItem.path ?? menuItem.component ?? menuItem.url;
            console.log('>> menu item clicked ', menuItem?.itemData);
            CommandManager.process(menuItem.url, menuItem.itemData ?? menuItem, { rows: SideMenu.targetsList });
        }
        //this.props.onItemClick?.(menuItem);
    }

    renderAccoSubItem = item => {
        return (
            <div style={{ display: 'inline-flex', overflow: 'hidden', paddingRight: '15px', width: '100%' }}
                onClick={() => this.onClickSubMenu(item)}
            >
                {//this.props.collapsed !== false &&
                    <img src={item.icon ?? `${window.config.API_PREFIX}/api/market/image/${item.sid}`}
                        width={40} height={30} style={{ objectFit: 'contain' }}
                        onError={e => e.target.src = '/images/no-image.png'}
                        alt=''
                    />
                }
                <span style={{ ...this.accoSubItemStyle, marginRight: '0.5rem' }}>{item.text}</span>
                {item.counter > 0 &&
                    <span>
                        <Badge color='rgb(0 0 255 / 65%)' size={20} text={`${item.counter}`}
                            ifNoText='hide' style={{ paddingTop: '3px' }}
                        />
                    </span>
                }
            </div>
        );
    }

    renderAccoTitle = item => {
        return (isMobile ? <></> :
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={item.icon ?? `${window.config.API_PREFIX}/api/market/image/${item.sid}`} width={40}
                    height={30} onError={e => e.target.src = '/images/no-image.png'}
                    style={{ marginLeft: '5px', objectFit: 'contain' }} alt='' //id={'acco_it_icon_' + item.id}
                    onMouseEnter={isMobile || !this.state.collapsed ? undefined
                        : e => {
                            if (this.state.hoveredItem?.id !== item.id)
                                this.setState({ hoveredItem: item, hoveredX: e.clientX, hoveredY: e.clientY });
                        }}
                //onMouseLeave={e => this.setState({ hoveredItem: undefined })}
                //onMouseOut={e => this.setState({ hoveredItem: '' })}
                />

                {this.state.collapsed ? <></>
                    // <ContextMenu items={item.items}//{this.state.items.find(x => x.id === item.id)?.items}
                    //     rtlEnabled target={`#acco_it_icon_${item.id}`} itemRender={this.renderAccoSubItem}
                    //     showEvent={{ name: 'mouseenter', delay: 0 }} visible={this.state.hoveredItem?.id === item.id}
                    // />
                    :
                    <span style={this.accoTitleStyle}>{item.text}</span>
                }
            </div>
        );
    }

    componentDidUpdate() {
        let titles = document.getElementsByClassName('dx-accordion-item-title');
        for (let x of titles)
            x.style.background = window.config?.THEME?.color1;// '#87ceeb61';
    }

    setCollapsed = collapsed => {
        this.setState({ collapsed, hoveredItem: undefined });
    }

    onCollapsedItemClick = e => {
        //e.itemElement.children[1].style.height = '0';
        //let subs = this.state.items.find(x => x.id === e.itemData.id)?.items;
    }

    render() {
        let ds = this.state.items; // this.state.collapsed ? this.state.items.map(i => ({ ...i, items: null })) : this.state.items;
        return (
            <>
                {!(isMobile && window.productToView > 0) &&
                    <div style={{
                        overflowX: 'hidden',
                        //overflowY: 'overlay',
                        position: 'relative',
                        height: `${MasterLayout.bodyHeight - 90/*- 0*/}px`
                    }}
                        onMouseEnter={!this.state.collapsed ? undefined : e => this.setState({ mouseInAcco: true })}
                        onMouseLeave={!this.state.collapsed ? undefined : e => this.setState({ mouseInAcco: false })}
                    >
                        <Accordion dataSource={ds} width={this.props.width ?? (isMobile ? '100%' : 250)}
                            key={`acco_${this.state.collapsed ? 1 : 0}`}
                            className='ka_right_accordion'
                            height='100%'//VisualViewport.availHeight}//{`${MasterLayout.bodyHeight}px`}
                            selectedIndex={this.state.collapsed ? -1 : this.state.selectedIndex}
                            onSelectedIndexChange={i => {
                                if (!this.state.collapsed)
                                    this.setState({ selectedIndex: i }, () => window.selectedIndex = i);
                            }}

                            focusStateEnabled activeStateEnabled hoverStateEnabled
                            collapsible={this.state.collapsed}

                            itemTitleRender={this.renderAccoTitle} //onItemRendered={i => console.log(i)}
                            itemRender={i => (
                                <List dataSource={i.items} itemRender={this.renderAccoSubItem}
                                    scrollingEnabled scrollByThumb
                                    height={this.state.collapsed ? 0 : (MasterLayout.bodyHeight/**/ - ds.length * 42) + 'px'}
                                    //height={MasterLayout.bodyHeight}
                                    visible={!this.state.collapsed} //scrollByContent={true}
                                />)
                            }
                            onContentReady={this.onAccoContentReady}
                            onItemClick={this.state.collapsed ? this.onCollapsedItemClick : undefined}
                        //onItemClick={this.state.collapsed ? e => this.onCollapsedItemClick(e) : e => this.onClickSubMenu(e)}
                        />

                    </div>
                }
                {!isMobile && this.state.collapsed && this.state.hoveredItem?.items?.length > 0 &&
                    <div style={{
                        position: 'fixed',
                        top: (this.state.hoveredY - 20) + 'px',
                        right: '40px',
                        background: window.config.THEME.color1,
                        zIndex: '1000',
                        width: '15rem'
                    }}
                        onMouseLeave={e => {
                            if (!this.state.mouseInAcco)
                                this.setState({ hoveredItem: undefined });
                        }}
                    >
                        <List items={this.state.hoveredItem.items} rtlEnabled
                            itemRender={this.renderAccoSubItem}
                        />
                    </div>
                }
            </>
        );
    }
}

export default SideMenu;