import React, { Component } from 'react';
import { apiGet } from '../../GanjiTools';
import { Button, TextBox } from 'devextreme-react';
import Spinner from '../utils/Spinner';

class Captcha extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        enteredCode: '',
    };

    reload = () => {
        this.setState({ enteredCode: '' }, () =>
            apiGet('/api/logon/captchaImage/' + this.props.userName,
                res => {
                    let el = document.getElementById('captcha-image-' + (this.props.sid ?? ''));
                    if (el)
                        el.src = res.error?.length > 0 ? '' : 'data:image/png;base64,' + res.image;
                }
            )
        );
    }

    componentDidMount() {
        this.reload();
    }

    getEnteredCode = () => this.state.enteredCode;

    render() {
        return (
            <div style={{ direction: 'ltr', display: 'flex', alignItems: 'center', padding: '5px', ...this.props.containerStyle }}>
                <img id={'captcha-image-' + (this.props.sid ?? '')} src='' style={{ margin: '0 5px' }} />
                <Button icon='refresh' stylingMode='text' onClick={this.reload} />
                <span style={{ width: '5px' }}>&nbsp;</span>
                <TextBox rtlEnabled={false} placeholder='کپچا'
                    value={this.state.enteredCode} onValueChanged={e => this.setState({ enteredCode: e.value })}
                />
            </div>
        );
    }
}

export default Captcha;