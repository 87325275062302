import React, { Component } from 'react';
import { apiGet, apiGetA } from '../GanjiTools';
import localforage from 'localforage';
//import { ResourceAssignments } from 'devextreme-react/gantt';

class UrlImage extends Component {

    constructor(props) {
        super(props);
        this.reload1 = this.reload1.bind(this);
    }
    state = { error: '' };
    id = 'url_image_' + this.props.sid;
    imageAvailable = false;

    /*
        reload = (dontCache, whenDone) => {
            this.imageAvailable = false;
            let img = document.getElementById(this.id);
    
            if (!img) {
                whenDone?.();
                return;
            }
    
            if (!dontCache) {
                localforage.getItem(this.id).then(cached => {
                    if (cached && cached != 'null' && cached != 'undefined') {
                        img.src = `data:image;base64,${cached}`;
                        this.imageAvailable = true;
                        whenDone?.(cached);
                        return;
                    }
                });
            }
    
            if (!(this.props.url?.length > 0)) {
                img.src = (this.props.errorImage ?? '');
                whenDone?.();
                return;
            }
    
            apiGet(this.props.url,
                res => {
                    this.setState({ error: res.error }, () => {
                        this.imageAvailable = !(res.error?.length > 0 || !res.image);
                        img.src = this.imageAvailable ? `data:image;base64,${res.image}` : (this.props.errorImage ?? '');
                        localforage.setItem(this.id, this.imageAvailable ? res.image : null);
                        whenDone?.(this.imageAvailable ? res.image : '');
                        return;
                    });
                },
                err => {
                    img.src = (this.props.errorImage ?? '');
                    whenDone?.();
                    return;
                }
            );
        }
    
        reload0 = dontCache => {
            this.imageAvailable = false;
            let img = document.getElementById(this.id);
    
            if (!img)
                return;
    
            if (!dontCache) {
                let cached = localStorage.getItem(this.id);
    
                if (cached && cached != 'null' && cached != 'undefined') {
                    img.src = `data:image;base64,${cached}`;
                    this.imageAvailable = true;
                    return;
                }
            }
    
            if (this.props.url?.length > 0)
                apiGet(this.props.url,
                    res => {
                        this.setState({ error: res.error }, () => {
                            this.imageAvailable = !(res.error?.length > 0 || !res.image);
                            img.src = this.imageAvailable ? `data:image;base64,${res.image}` : (this.props.errorImage ?? '');
                            try {
                                localStorage.setItem(this.id, this.imageAvailable ? res.image : null);
                            }
                            catch (e) {
                            }
                        });
                    },
                    err => img.src = (this.props.errorImage ?? '')
                )
            else
                img.src = (this.props.errorImage ?? '')
        }
    */

    async reload1(dontCache) {
        this.imageAvailable = false;
        let img = document.getElementById(this.id);

        if (!img)
            return;

        if (!dontCache) {
            let cached = await localforage?.getItem(this.id);

            if (cached && cached != 'null' && cached != 'undefined') {
                img.src = `data:image;base64,${cached}`;
                this.imageAvailable = true;
                //console.log('cached image is loaded: ', this.id);
                return;
            }
        }

        if (this.props.url?.length > 0) {
            let res = {};
            try {
                res = await apiGetA(this.props.url);
            } catch (e) {
                res = { error: 'error=' + e }
            }
            this.imageAvailable = !(res.error?.length > 0 || !res.image);

            this.setState({ error: res.error }, () => {
                //this.imageAvailable = !(res.error?.length > 0 || !res.image);
                img.src = this.imageAvailable ? `data:image;base64,${res.image}` : (this.props.errorImage ?? '');
                localforage?.setItem(this.id, this.imageAvailable ? res.image : null).then().catch();
            });
        }
        else
            img.src = (this.props.errorImage ?? '')
    }

    componentDidMount() {
        //this.reload(false);////
        //this.reload1(false);
    }

    render() {
        return (
            <>
                {this.props.previewHeight > 0 &&
                    <img className='ka-shaddowed'
                        id={this.props.sid + '_preview'}
                        height={this.props.previewHeight}
                        style={{ display: 'none', zIndex: '1000', position: 'fixed', left: '10px', top: '10px' }}
                        alt=''
                    />
                }
                {this.state.error?.length > 0
                    ?
                    <div className={this.props.className ?? ''} style={this.props.style} onClick={this.props.onClick}>
                        {this.state.error}
                    </div>
                    :
                    <img className={'ka-highlight-on-hover ' + (this.props.className ?? '')}
                        id={this.id} src={this.props.errorImage ?? ''}
                        width={this.props.width} height={this.props.height}
                        style={{ cursor: this.props.onClick ? 'pointer' : 'default', ...this.props.style }}
                        alt=''
                        onClick={this.props.onClick}
                        onLoad={e => {
                            this.reload1(this.props.dontCache).then().catch();
                        }}

                        onMouseLeave={e => {
                            if (!(this.props.previewHeight > 0))
                                return;

                            let preview = document.getElementById(this.props.sid + '_preview');
                            preview.style.display = 'none';
                            preview.src = undefined;
                        }}

                        onMouseEnter={e => {
                            if (/*!this.imageAvailable ||*/ !(this.props.previewHeight > 0))
                                return;

                            let preview = document.getElementById(this.props.sid + '_preview');// ?? document.createElement('img', { display: 'none', id: this.props.sid + '_preview' });

                            if (preview.style.display === 'block')
                                return;

                            preview.style.display = 'block';
                            preview.src = e.target.src;
                            preview.style.top = (e.screenY - 150) + 'px';
                            preview.style.left = (e.screenX - 250) + 'px';
                            //console.log(e);
                        }}
                    />
                }
            </>
        );
    }
}

export default UrlImage;