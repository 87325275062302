import React, { Component } from 'react';
import { Calendar, DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import persian_en from "react-date-object/locales/persian_en";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Url } from 'devextreme-react/chart';
import { Button, Popup } from 'devextreme-react';
import { Tarikh, StringFormat } from '../GanjiTools';
import './theme/additional.css';

class TarikhTimePicker extends Component {
    constructor(props) {
        super(props);

        let ymdhm = props.data?.value ?? props.value;
        ymdhm = ymdhm?.replace('T', ' ').replaceAll('-', '/');
        let ss = ymdhm?.length > 0 ? ymdhm.split(' ') : [], hms = [], hour = 0, minute = 0, second = 0;
        if (ss.length > 0)
            hms = ss[1]?.split(':') ?? [];

        if (hms?.length > 0)
            hour = Number(hms[0] ?? '0');
        if (hms?.length > 1)
            minute = Number(hms[1] ?? '0');
        if (hms?.length > 2)
            second = Number(hms[2] ?? '0');

        this.date = new DateObject({
            date: ymdhm,
            format: "YYYY/MM/DD HH:mm",
            calendar: persian,
            locale: persian_fa,
            hour,
            minute,
            second
        });

        //this.displayText = `${'%4d'.format(this.date.year)}/${'%02d'.format(this.date.month)}/${'%02d'.format(this.date.day)}`;

        this.state = {
            value: props.data?.value ?? props.value
        };
    }

    componentDidMount() {
    }

    render0() {
        return (
            <div className='ka-tarikh-picker' style={{ direction: "rtl" }}>
                <DatePicker
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-right"
                />
            </div>
        )
    }

    render() {
        //console.log(' ---- tt -----', this.state.value, this.props.value);
        return (
            <div key={'tp' + this.state.value}>
                <Popup
                    visible={this.state.pickerVisible}
                    //onHiding={e => console.log('hiding ', e)}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={false}
                    //title="تاریخ"
                    //container=".dx-viewport"
                    width='fit-content'
                    height='fit-content'
                    style={{ padding: '0' }}
                >
                    <Calendar
                        minDate={this.props.special ? new Date() : undefined} /////////////////////
                        //maxDate={this.props.special ? new Date().getDateObject().add(this.props.isDaily !== false ? 1 : 30, 'days').toDate() : undefined}
                        value={this.date} readOnly={this.props.readOnly} //.state.value} 
                        plugins={[
                            <TimePicker position="bottom" hideSeconds //onSubmit={e => console.log(e)}
                            />
                        ]}
                        //format='yyyy/MM/dd HH:mm'
                        calendar={persian}
                        locale={persian_fa}
                        onChange={e => { //console.log("%05d".$(12)); // '00012'
                            this.tempValue = new Tarikh(e.toDate()).getString() + ' ' + '%02d'.format(e.hour) + ':' + '%02d'.format(e.minute)
                            this.date = e.toDate();
                        }}
                        style={{ margin: '0' }}
                    />
                    <Button width={'50%'} onClick={e => this.setState({ value: this.tempValue, pickerVisible: false },
                        () => {
                            (this.props.onValueChanged ?? this.props.onChanged)?.(this.tempValue, new DateObject({ date: this.date }));
                            this.props.data?.setValue(this.tempValue);
                        })}
                    >
                        قبول
                    </Button>
                    <Button width={'50%'} onClick={e => this.setState({ pickerVisible: false })}>
                        رد
                    </Button>
                </Popup>
                <TextBox key={this.state.value ?? this.props.value ?? this.props.data?.value}
                    text={this.displayText ?? this.state.value ?? this.props.value ?? this.props.data?.value}
                    rtlEnabled={false} readOnly={this.props.readOnly}
                > {/*defaultValue={this.props.data.value}*/}
                    <TextBoxButton name='tarikh' location='after' className='dx-icon-font'
                        options={{
                            icon: 'event',
                            onClick: e => this.props.readOnly ? e => { } : this.setState({ pickerVisible: true })
                        }} />
                </TextBox>
            </div>
        );
    }
}

export default TarikhTimePicker;