import React, { Component } from 'react';
import { apiGetP } from '../../GanjiTools';
import { UniGrid } from '../UniGrid';

class PropertyValues extends Component {
    state = {
        table: this.props.table ?? '',
        owner: this.props.ownerId,
        props: [],
        grdKey: 1,
    }

    loadDataP = (ownerId, table) => new Promise((resolve, reject) => {
        if (!(ownerId > 0) || !(table?.length > 0)) {
            reject?.(`Invalid table or ownerId: ${table}.${ownerId}`);
            return;
        }

        apiGetP(`/api/market/propVals?ownerId=${ownerId}&table=${table}`)
            .catch(reject)
            .then(res => {
                res ??= { error: 'No response.' };
                if (res.error?.length > 0) {
                    reject?.(res.error);
                    return;
                }

                let { rows, cols } = res;

                resolve?.({ rows, cols });
            });
    });

    componentDidMount() {
        if (this.props.ownerId > 0)
            this.loadDataP(this.props.ownerId, this.props.table).catch(window.notif)
                .then(res => {
                    this.setState({ ...(res ?? {}), grdKey: this.state.grdKey + 1 });
                });
    }

    render() {
        return (
            <>
                <UniGrid key={'ged' + this.state.grdKey + '_' + this.props.ownerId}
                    title={this.props.customerMode ? '' : 'مشخصات'}
                    rows={this.state.rows} columns={this.state.cols}
                    allowAdding={false} allowDeleting={false}
                    width={this.props.width}
                    readOnly={this.props.customerMode}
                    ignoreUserCheck={this.props.customerMode}
                    headerFilter={this.props.customerMode}
                    noToolbar={this.props.customerMode}
                    filterRow={!this.props.customerMode}
                    showColumnHeaders={!this.props.customerMode}
                    sequenceColumn={!this.props.customerMode}
                    xProps={{
                        showColumnLines: !this.props.customerMode,

                    }}

                />
            </>
        );
    }
}

export default PropertyValues;