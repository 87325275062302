import { DateObject } from "react-multi-date-picker";

export const apiPrefix = window.config.API_PREFIX ?? ''; // process.env.REACT_APP_API_PREFIX; //env.API_PREFIX; // configData.API_PREFIX; //  'https://5.201.164.56'; //  'https://localhost'; // 'https://10.0.0.33:443'; // 'https://localhost:443'; // 'https://cors-everywhere.herokuapp.com/' + 'http://10.0.0.33:5009'; // 'http://localhost:8094'; //

export function toLowerKeys(x, recursive = true, alsoValues = false) {
    if (!x || typeof (x) !== 'object')
        return x;

    if (Array.isArray(x))
        return x.map(a => toLowerKeys(a, recursive, alsoValues));

    let keys = Object.keys(x);
    let xlo = {};
    keys.forEach(k => xlo[k.toLowerCase()] = !recursive ? (alsoValues && typeof (x[k]) == 'string' ? (x[k]?.toLowerCase()) : x[k]) : toLowerKeys(x[k], recursive, alsoValues));
    return xlo;
}

export function toTreeRows(rows, parentFieldName, key = 'id', iconOfRow = (row => ''), childrenPropName = 'children', displayPropName = 'name') {
    if (!parentFieldName || !(rows?.length > 0))
        return rows;

    let res = [];
    for (let row of rows) {
        if (!row[parentFieldName])
            res.push(toTreeRow(row, rows, parentFieldName, key, displayPropName, iconOfRow, childrenPropName));
    }

    return res;
}

function toTreeRow(row, rows, parentFieldName, key = 'id', label = 'name', iconOfRow = (row => ''), childrenPropName = 'children') {
    let kids = rows.filter(x => x[parentFieldName] === row[key])?.map(x => toTreeRow(x, rows, parentFieldName, key, label, iconOfRow, childrenPropName));
    let res = { key: row[key], data: row, label: row[label], icon: iconOfRow?.(row) ?? '' };
    if (kids?.length)
        res[childrenPropName] = kids;

    return res;
}



export function updateTree(toBeUpdated, changes, addNewItems = true) {
    if (!changes || typeof (changes) !== 'object')
        return;

    // if (Array.isArray(x))
    //     return x.map(a => toLowerKeys(a, recursive, alsoValues));

    let keys = Object.keys(changes);

    keys?.forEach(k => {
        if (!toBeUpdated[k]) {
            if (addNewItems)
                toBeUpdated[k] = changes[k];
        }
        else if (typeof (changes[k]) !== 'object')
            toBeUpdated[k] = changes[k];
        else
            updateTree(toBeUpdated[k], changes[k]);
    });
}

export function updateTreeThenReport(toBeUpdated, changes, addNewItems = true, removeIf = prop => false) {
    if (!changes || typeof (changes) !== 'object')
        return;

    // if (Array.isArray(x))
    //     return x.map(a => toLowerKeys(a, recursive, alsoValues));

    let keys = Object.keys(changes);

    keys?.forEach(k => {
        if (!toBeUpdated[k]) {
            if (addNewItems) {
                console.log(toBeUpdated, ' <added> ', k, ':', changes[k]);
                toBeUpdated[k] = changes[k];
            }
        }
        else if (removeIf(changes[k])) {
            console.log(toBeUpdated, '.', k, ' <<= undefined');
            toBeUpdated[k] = undefined;
        }
        else if (typeof (changes[k]) !== 'object') {
            console.log(toBeUpdated, '.', k, ' <<==', changes[k]);
            toBeUpdated[k] = changes[k];
        }
        else
            updateTree(toBeUpdated[k], changes[k]);
    });
}

//export function apiGet(url, whenDone) {
//    url = apiPrefix + url;
//    fetch(url, {
//        method: 'GET',
//        mode: 'no-cors' /**/
//    }).then(d => d.json().then(resp => whenDone?.(resp)))
//        .catch(err => console.log('error getting ', url, '\n >> ', err));
//}

export function apiGet(url, whenDone, whenError) {
    window.logFetchs && console.log('...getting from ' + url);

    if (!(url?.length > 0)) {
        console.log('error! url is null.');
        whenError?.('url is null!');
        return;
    }

    url = apiPrefix + url;
    //url = `https://api.allorigins.win/get?url=${encodeURIComponent(apiPrefix + url)}`;
    //url = `https://cors-proxy.htmldriven.com/?url=${encodeURIComponent(apiPrefix + url)}`;
    //console.log('fetching ', url);

    fetch(url, { headers: { 'logonkey': window.user?.logonkey } }/* {
        headers: { 'Connection': 'keep-alive', 'Accept-Encoding': 'gzip, deflate, br' },
        method: 'GET'
    }*/)
        .then(response => {
            if (response?.ok)
                return response.json();

            console.log('network response was not ok: ', response);
            throw new Error('network response was not ok: ' + response);
        })
        .then(data => {
            if (data?.error === '<invalid_user>')
                window.logout?.(() => console.log(`#error @${data.sid}: invalid user. `, data.details));
            else
                whenDone?.(data);
        },
            err => {
                console.log('error fetching ', url, '=>\n ', err);
                whenError?.(err);
            })
        .catch(whenError);

    //--------------------------------------------------------------------------------------------
    //    url = apiPrefix + url;
    //    fetch(url, {
    //        method: 'GET',
    //        //mode: 'no-cors' /**/
    //    }).then(d => {
    //        console.log('d=', d);
    //        d.json().then(resp => {
    //            console.log('resp of GET=', resp);
    //            whenDone?.(resp);
    //        });
    //    }).catch(err => console.log('error getting ', url, '\n >> ', err));
}

export function apiGetP(url) {
    return new Promise((resolve, reject) => {
        apiGet(url, resolve, reject)
    });
}

export async function apiGetA(url) {
    return apiGetP(url);
}


export function apiPost(url, data = {}, whenDone, whenError) {
    window.logFetchs && console.log('...posting ', data, ' to ', url);
    if (!(url?.length > 0)) {
        console.log('error! url is null.');
        whenError?.('url is null!');
        return;
    }

    fetch(apiPrefix + url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'logonkey': window.user?.logonkey /*, 'Connection': 'keep-alive' */ },
        body: JSON.stringify(data)
    }).then(d => d.ok
        ? d.json().then(resp => {
            if (resp.error === '<invalid_user>')
                window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
            else
                whenDone?.(resp);
        },
            err => {
                console.log('error posting ', url, data, '=>\n ', err);
                whenError?.(err);
            })
        : console.log('post was not ok: ', data, '=>\n ', whenError?.(d))
    ).catch(err => {
        console.log('error posting ', url, data, '\n >> ', err);
        whenError?.(err);
    });
}


export function apiPostP(url, data = {}) {
    return new Promise((resolve, reject) => {
        window.logFetchs && console.log('...posting ', data, ' to ', url);
        if (!(url?.length > 0)) {
            console.log('error! url is null.');
            reject?.(new Error('url is null!'));
            return;
        }

        fetch(apiPrefix + url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'logonkey': window.user?.logonkey /*, 'Connection': 'keep-alive' */ },
            body: JSON.stringify(data)
        }).then(d => d.ok
            ? d.json().then(resp => {
                if (resp.error === '<invalid_user>')
                    window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
                else
                    resolve?.(resp);
            },
                err => {
                    console.log('error posting ', url, data, '=>\n ', err);
                    reject?.(err);
                })
            : console.log('post was not ok: ', data, '=>\n ', reject?.(d))
        ).catch(err => {
            console.log('error posting ', url, data, '\n >> ', err);
            reject?.(err);
        });
    });
}

export async function apiPostA(url, data = {}) {
    window.logFetchs && console.log('...posting ', data, ' to ', url);
    if (!(url?.length > 0)) {
        console.log('error! url is null.');
        throw new Error('url is null!');
    }

    fetch(apiPrefix + url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'logonkey': window.user?.logonkey /*, 'Connection': 'keep-alive' */ },
        body: JSON.stringify(data)
    }).then(d => {
        if (d.ok)
            d.json().then(resp => {
                if (resp.error !== '<invalid_user>')
                    return resp;
                window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
                throw new Error(`#error @${resp.sid}: invalid user. ` + resp.details);
            }).catch(err => {
                console.log('error posting ', url, data, '=>\n ', err);
                throw err;
            });
        else {
            console.log('post was not ok: ', data, '=>\n ', d);
            throw new Error('post was not ok: ' + data + '=>\n ' + d);
        }
    }).catch(err => {
        console.log('error posting ', url, data, '\n >> ', err);
        throw err;
    });
}


export function apiPostNoBody(url, whenDone, whenError) {
    if (!(url?.length > 0)) {
        console.log('error! url is null.');
        whenError?.('url is null!');
        return;
    }
    fetch(apiPrefix + url, {
        method: 'POST',
        headers: { 'logonkey': window.user?.logonkey },
        //headers: { 'Content-Type': 'application/json' },
    }).then(d => d.json().then(resp => {
        if (resp.error === '<invalid_user>')
            window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
        else
            whenDone?.(resp);
    },
        err => {
            whenError?.(err);
            console.log('error posting ', url, '=>\n ', err);
        }))
        .catch(err => {
            whenError?.(err);
            console.log('error posting ', url, '\n >> ', err);
        });
}

export function apiPostNoBodyP(url) {
    return new Promise((resolve, reject) => {
        if (!(url?.length > 0)) {
            console.log('error! url is null.');
            reject?.('url is null!');
            return;
        }

        fetch(apiPrefix + url, {
            method: 'POST',
            headers: { 'logonkey': window.user?.logonkey },
            //headers: { 'Content-Type': 'application/json' },
        }).then(d => d.json().then(resp => {
            if (resp.error === '<invalid_user>')
                window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
            else
                resolve?.(resp);
        },
            err => {
                reject?.(err);
                console.log('error posting ', url, '=>\n ', err);
            }))
            .catch(err => {
                reject?.(err);
                console.log('error posting ', url, '\n >> ', err);
            });
    });
}


export function apiPut(url, data = {}, whenDone = null, whenError = null) {
    if (!(url?.length > 0)) {
        console.log('error! url is null.');
        whenError?.('url is null!');
        return;
    }

    fetch(apiPrefix + url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'logonkey': window.user?.logonkey },
        body: JSON.stringify(data)
    }).then(d => d.json().then(resp => {
        if (resp.error === '<invalid_user>')
            window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
        else
            whenDone?.(resp);
    },
        err => {
            console.log('error posting ', url, '=>\n ', err);
            whenError?.(err);
        }))
        .catch(err => {
            console.log('error putting ', url, '\n >> ', err);
            whenError?.(err);
        });
}

export function apiDeleteP(url) {
    return new Promise((resolve, reject) => {
        if (!(url?.length > 0)) {
            console.log('error! url is null.');
            reject?.('url is null!');
            return;
        }

        fetch(apiPrefix + url, {
            method: 'DELETE',
            headers: { 'logonkey': window.user?.logonkey }
        })
            .then(d => d.json().then(resp => {
                if (resp.error === '<invalid_user>') {
                    window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
                    return;
                }
                if (resp?.error?.length > 0) {
                    reject?.(resp.error);
                    return;
                }
                resolve?.(resp);
            },
                err => {
                    reject?.(err);
                    console.log('error posting ', url, '=>\n ', err);
                }))
            .catch(err => {
                reject?.(err);
                console.log('error deleting ', url, '\n >> ', err);
            });
    });
}

export function apiDelete(url, whenDone, whenError) {
    if (!(url?.length > 0)) {
        console.log('error! url is null.');
        whenError?.('url is null!');
        return;
    }

    fetch(apiPrefix + url, {
        method: 'DELETE',
        headers: { 'logonkey': window.user?.logonkey }
    }).then(d => d.json().then(resp => {
        if (resp.error === '<invalid_user>')
            window.logout?.(() => console.log(`#error @${resp.sid}: invalid user. `, resp.details));
        else
            whenDone?.(resp);
    },
        err => {
            whenError?.(err);
            console.log('error posting ', url, '=>\n ', err);
        }))
        .catch(err => {
            whenError?.(err);
            console.log('error deleting ', url, '\n >> ', err);
        });
}

export function fetchLookupP(url_or_table, options) {
    let { dispForNew, dispForNull, includeOriginalRow, field, equalto, not_equalto } = options ?? {};
    return new Promise((resolve, reject) => {
        if (!(url_or_table?.length > 0)) {
            reject?.(new Error('no url nor dataSource!'));
            return;
        }

        let url = url_or_table?.includes('/') ? url_or_table
            : (`/api/uni/lookup4/${url_or_table}`
                + (field?.length > 0 ? `/${field}/${equalto ?? not_equalto}` : '')
                + '?dispForNew=' + (dispForNew ?? '<>')
                + '&dispForNull=' + (dispForNull ?? '<>')
                + '&includeOrgRow=' + (includeOriginalRow ? 'y' : 'n')
                + '&invertEqualTo=' + (equalto ? 'n' : 'y')
            );

        apiGet(url, resolve, reject);
    });
}

export const StringFormat = function (str, arr) {
    var i = -1;
    function callback(exp, p0, p1, p2, p3, p4) {
        if (exp === '%%')
            return '%';

        if (arr[++i] === undefined) return undefined;
        exp = p2 ? parseInt(p2.substr(1)) : undefined;
        var base = p3 ? parseInt(p3.substr(1)) : undefined;
        var val;
        switch (p4) {
            case 's': val = arr[i]; break;
            case 'c': val = arr[i][0]; break;
            case 'f': val = parseFloat(arr[i]).toFixed(exp); break;
            case 'p': val = parseFloat(arr[i]).toPrecision(exp); break;
            case 'e': val = parseFloat(arr[i]).toExponential(exp); break;
            case 'x': val = parseInt(arr[i]).toString(base ? base : 16); break;
            case 'd': val = parseFloat(parseInt(arr[i], base ? base : 10).toPrecision(exp)).toFixed(0); break;
        }
        val = typeof (val) == 'object' ? JSON.stringify(val) : val.toString(base);
        var sz = parseInt(p1); /* padding size */
        var ch = p1 && p1[0] == '0' ? '0' : ' '; /* isnull? */
        while (val.length < sz) val = p0 !== undefined ? val + ch : ch + val; /* isminus? */
        return val;
    }
    var regex = /%(-)?(0?[0-9]+)?([.][0-9]+)?([#][0-9]+)?([scfpexd%])/g;
    return str.replace(regex, callback);
}

String.prototype.$ = function () {
    return StringFormat(this, Array.prototype.slice.call(arguments));
}


String.prototype.format = function () {
    return StringFormat(this, Array.prototype.slice.call(arguments));
}

/* examples:
 * String.format("%s %s", [ "This is a string", 11 ])
console.log("%s %s".$("This is a string", 11))
var arr = [ "12.3", 13.6 ]; console.log("Array: %s".$(arr));
var obj = { test:"test", id:12 }; console.log("Object: %s".$(obj));
console.log("%c", "Test");
console.log("%5d".$(12)); // '   12'
console.log("%05d".$(12)); // '00012'
console.log("%-5d".$(12)); // '12   '
console.log("%5.2d".$(123)); // '  120'
console.log("%5.2f".$(1.1)); // ' 1.10'
console.log("%10.2e".$(1.1)); // '   1.10e+0'
console.log("%5.3p".$(1.12345)); // ' 1.12'
console.log("%5x".$(45054)); // ' affe'
console.log("%20#2x".$("45054")); // '    1010111111111110'
console.log("%6#2d".$("111")); // '     7'
console.log("%6#16d".$("affe")); // ' 45054'
 */


export class Tarikh extends Date {
    constructor(...args) {
        super(...args);
    }

    getString() {//this.toLocaleDateString('fa-IR-u-nu-latn');
        let smr = this.smr();
        return '%04d'.$(smr.sal) + '/' + '%02d'.$(smr.mah) + '/' + '%02d'.$(smr.ruz);
    }
    getParts = () => this.toLocaleDateString('fa-IR-u-nu-latn').split("/")
    getDay = () => super.getDay() === 6 ? 0 : super.getDay() + 1
    getDate = () => this.getParts()[2];
    getMonth = () => this.getParts()[1];
    getYear = () => this.getParts()[0];
    getMonthName = () => this.toLocaleDateString("fa-IR", { month: 'long' });
    getDayName = () => this.toLocaleDateString("fa-IR", { weekday: 'long' });
    smr = () => ({ sal: Number(this.getYear()), mah: Number(this.getMonth()), ruz: Number(this.getDate()) });
    static emruz = () => new Tarikh();
    static emruzString = () => new Tarikh().getString();
    static fardaString = () => new Tarikh().addDays(1).getString();
    static diruzString = () => new Tarikh().addDays(-1).getString();
    addDays(days) {
        var result = new Tarikh(this);
        result.setDate(Number(result.getDate()) + days);
        return result;
    }

    toDateObject = () => new DateObject({ date: this });
}

Date.prototype.getDateObject = () => new DateObject({ date: this });
/*
String.prototype.hashCode = function () {
    let hash = 0, i, chr;
    if (this.length === 0)
        return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
 
Number.prototype.hashCode = function () {
    return this.toString().hashCode();
}
 
Object.prototype.hashCode = function () {
    let hash = 0, i;
    let vals = [...Object.keys(this)?.map(x => x.toString()), ...Object.values(this)?.map(x => x.toString())];
    if ((vals?.length ?? 0) === 0)
        return hash;
 
    for (i = 0; i < vals.length; i++) {
        hash ^= vals[i].hashCode();
    }
    return hash;
}
 
Array.prototype.hashCode = function () {
    let hash = 0, i;
    for (i = 0; i < this.length; i++) {
        hash ^= this[i].hashCode();
    }
    return hash;
}
*/

//let crows = [];
//for (let row of rows) {
//    let crow = {};
//    for (let key of Object.keys(row))
//        crow[key.toLowerCase()] = row[key];

//    crows.push(crow);
//}

export function addCssFile(cssFileName) {
    var head = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = cssFileName;
    //link.media = 'all';
    head.appendChild(link);
}

export function removeFile(filename, filetype = 'css') {
    var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none"; //determine element type to create nodelist from
    var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none"; //determine corresponding attribute to test for
    var allsuspects = document.getElementsByTagName(targetelement);
    for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
        if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1)
            allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
    }
}
//removeFile("somescript.js", "js") //remove all occurences of "somescript.js" on page
//removeFile("somestyle.css", "css") //remove all occurences "somestyle.css" on page

export function setCookie(cname, cvalue, expHours = 24) {
    const d = new Date();
    d.setTime(d.getTime() + (expHours * 3600000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const deleteCookie = (name) => {
    setCookie({ name: name, value: "", seconds: 1 });
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function generateUUID() {
    let d = new Date().getTime(),
        d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
        c => {
            let r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
}

