import React, { Component } from 'react';
import Space from './Space';
import { generateUUID } from '../GanjiTools';
import { Tooltip } from 'devextreme-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MinimalButton extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.sid?.length > 0 ? this.props.sid + '_container' : generateUUID();
        this.state = { bg: this.props.normalBackground ?? 'transparent' };
    }

    onContainerLoad = e => {
        console.log(e);
    }

    componentDidMount() {
        this.setState({ containerElem: document.getElementById(this.id) });
    }

    onMouseEnter = e => {
        this.setState({ bg: this.props.highlightedBackground ?? 'white' });
    }

    onMouseLeave = e => {
        this.setState({ bg: this.props.normalBackground ?? 'transparent' });
    }

    render() {
        let iconBefore = this.props.iconBefore ?? this.props.icon;
        //let id = this.props.sid?.length > 0 ? this.props.sid + '_container' : generateUUID();
        return (
            <>
                <div onClick={this.props.onClick} id={this.id} onEnded={this.onContainerLoad}
                    onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        cursor: 'pointer',
                        padding: '0',
                        width: this.props.width ?? 'auto',
                        height: this.props.height ?? 'auto',
                        ...this.props.style,
                        background: this.state.bg,
                    }}
                //id={this.props.id + '_container'}
                >
                    {iconBefore && <span className={'dx-icon-' + iconBefore} style={this.props.iconStyle}></span>}
                    {iconBefore && <Space width={this.props.spacing ?? '5px'} />}
                    <span style={{ color: this.props.color ?? 'inherit', ...this.props.textStyle }}>
                        {this.props.text}
                    </span>
                    {this.props.children}
                    {this.props.iconAfter && <Space width={this.props.spacing ?? '5px'} />}
                    {this.props.iconAfter && <span className={'dx-icon-' + this.props.iconAfter} style={this.props.iconStyle}></span>}
                </div>
                {this.props.hint && this.state.containerElem &&
                    <Tooltip target={this.state.containerElem} hideEvent='mouseleave'
                        showEvent={{ name: 'mouseenter', delay: 1000 }}
                    >
                        {this.props.hint}
                    </Tooltip>
                }
            </>
        );
    }
}

export default MinimalButton;