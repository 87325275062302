import React, { Component } from 'react';

class Badge extends Component {

    render() {
        let txt = this.props.text ?? '';
        if (txt === 'null' || txt == null)
            txt = '';

        let size = this.props.size > 0 ? this.props.size : 20;
        return (
            txt?.length > 0 || this.props.ifNoText !== 'hide'
                ?
                <div style={{
                    width: size + 'px',
                    height: size + 'px',
                    borderRadius: size / 2 + 'px',
                    background: this.props.color ?? 'blue',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: size * 0.55 + 'px',
                    fontWeight: 900,
                    color: this.props.textColor ?? 'white',
                    ...this.props.style,
                }}
                >
                    {txt === '' ? (this.props.ifNoText ?? txt) : txt}
                </div>
                :
                <></>
        );
    }
}

export default Badge;