import React, { Component } from 'react';
import { Button, List } from 'devextreme-react';
import { TopMenu } from './TopMenu';
import SideMenu from './SideMenu';
import SideDivider from './SideDivider';
import UniPopup from '../UniPopup';
import UserInfo from '../user/UserInfo0';
import Header from './Header';
import Footer from './Footer';
import Space from '../Space';
import MinimalButton from '../MinimalButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Globals from '../Globals';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './MasterLayout.css';
import Inline from '../Inline';

class MasterLayout extends Component {
    // ww = isMobile ? Screen.availWidth : window.innerWidth;
    // hh = isMobile ? Screen.availHeight : window.innerHeight;
    ww = isMobile ? VisualViewport.availWidth : window.innerWidth;
    hh = isMobile ? VisualViewport.availHeight : window.innerHeight;

    state = {
        imageKeyCounter: 1,
        menuHeight: this.props.noTopMenu !== true ? 0 : (this.props.menuHeight ?? TopMenu.height ?? 80),
        headerHeight: this.props.headerHeight ?? 35,
        footerHeight: this.props.footerHeight ?? 0, // 35,
        accoWidth: this.props.accoWidth ?? (window.productToView > 0 ? 0 : isMobile ? '100vw' : 250),
        sideMaxWidth: this.props.sideMaxWidth ?? (window.productToView > 0 ? 0 : isMobile ? '100vw' : 270),
        sideKey: 1,
        body: this.props.body ?? <div>خالی</div>,
        showDevices: false,
    };

    onUserIconClick = e => {
        this.setState({ userPopupVisible: true });
    }

    getBodyHeight = () => MasterLayout.bodyHeight;

    static onResizeEvent = [
        // {key, caller},
    ];

    componentDidMount() {
        window.masterLayoutComponent = this;
    }

    render() {
        if (this.props.noLayout)
            return this.state.body;

        //console.log('window inner w,h= ', window.innerWidth, window.innerHeight, window.screen, Screen);
        window.masterLayoutComponent = this;
        let px = n => n + 'px';
        MasterLayout.bodyHeight = this.hh - this.state.menuHeight - this.state.footerHeight - this.state.headerHeight;
        let closeUserPopup = () => this.setState({ userPopupVisible: false });

        return (
            <>
                <div id='master_layout' style={{ direction: 'rtl' }} ref={el => window.masterLayoutRef = el}>
                    {this.props.noTopMenu !== true &&
                        <div id='master_top_menu'
                            style={{
                                height: px(this.state.menuHeight), width: '100vw',
                                display: this.props.noTopMenu !== true ? 'initial' : 'none'
                            }}
                        >
                            <TopMenu />
                        </div>
                    }

                    <div id='header' style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: window.config?.THEME?.color1,
                        width: isMobile ? this.ww + 'px' : '100%',
                        height: px(this.state.headerHeight)
                    }}>
                        <Header height={this.state.headerHeight} onUserIconClick={this.onUserIconClick}
                            nearItems={isMobile ? <></> :
                                <>
                                    <Button height='95%' icon='menu' stylingMode='text'
                                        width={isMobile ? '50px' : undefined}
                                        //ref={el => this.sideTogglerButton = el}
                                        onClick={e => {
                                            this.sideDivider?.toggleCollapsing?.(this.sideMenu?.setCollapsed);
                                        }}
                                    />
                                    <Space width={10} />
                                    <Inline spacing='5px'>
                                        {this.props.headerExtraItems}
                                    </Inline>
                                </>
                            }
                            onShowDevices={() => this.setState({ showDevices: !this.state.showDevices })}
                        />
                    </div>

                    {false &&
                        <div style={{
                            zIndex: '2',
                            position: 'fixed',
                            top: '100px',
                            left: '100px',
                            width: 'calc(100vw - 200px)',
                            height: this.state.showDevices ? (this.hh - 200) + 'px' : '0', //'calc(100vh - 200px)' : '0',// '60vh',
                            overflow: 'hidden',
                            transition: '0.5s',
                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 19px 38px, rgba(0, 0, 0, 0.3) 0px 15px 12px',
                            //boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                            //boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                            borderRadius: '10px',
                            background: window.config.THEME.color1,
                        }}>
                            <div style={{
                                width: '100%',
                                height: '100%',// '60vh',
                                border: '5px solid white', // + window.config.THEME.color1,
                                borderRadius: '10px',
                                overflow: 'hidden',
                                padding: '5px',
                                position: 'relative',
                            }}>
                                {/* <AllSchemas height={window.innerHeight - 260}//'calc(100vh - 200px)'
                                onRecordSelected={selectedRecord => this.setState({ selectedRecord, showDevices: false },
                                    () => {
                                        if (selectedRecord.table === 'list')
                                            window.setInnerBody(<DevicePage equipment={selectedRecord.row} />);
                                        else if (selectedRecord.table === 'tree' && selectedRecord.row?.fk_equipment > 0)
                                            window.setInnerBody(<DevicePage equipment={{ id: selectedRecord.row.fk_equipment }} />);
                                        else
                                            window.setInnerBody(<div style={{ padding: '3rem', opacity: '0.5' }}>تجهیز قابل نمایشی انتخاب نشد</div>);
                                    }
                                )}
                            /> */}
                                <div style={{ position: 'absolute', left: '5px', top: '5px' }}>
                                    <Button width='2.5rem' onClick={() => this.setState({ showDevices: false })}>
                                        <FontAwesomeIcon icon='close' fontSize='1.3rem' />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }

                    {isMobile && !(window.productToView > 0) &&
                        <SideMenu height={MasterLayout.bodyHeight - TopMenu.height - 145} width='100vw'
                            ref={el => { this.sideMenu = el; window.sideMenuRef = el; }}
                            key={this.state.sideKey}
                            collapsed={false}
                            onClickSubMenu={Globals.onClickSideSubMenu}
                        />
                    }
                    {true &&
                        <SideDivider ref={el => this.sideDivider = el} noFar //farInFront
                            minWidth={isMobile || window.productToView > 0 ? 0 : 50} maxWidth={this.state.sideMaxWidth}
                            height={MasterLayout.bodyHeight - 115}
                            minFarWidth={0} maxFarWidth={/*window.innerWidth * 0.75*/ 300}
                            //onCollapsed={sideCollapsed => this.setState({ sideCollapsed })}
                            //toggleBy={this.sideTogglerButton}

                            side={isMobile || window.productToView > 0 ? undefined :
                                <SideMenu height={MasterLayout.bodyHeight - 115} width={this.state.sideMaxWidth}
                                    ref={el => { this.sideMenu = el; window.sideMenuRef = el; }}
                                    key={this.state.sideKey}
                                    collapsed={this.state.collapsed}
                                    onClickSubMenu={Globals.onClickSideSubMenu}
                                />
                            }

                            body={this.state.body} bodyKey={this.props.bodyKey ?? 1}

                            farSide={
                                <div style={{ zIndex: '2', position: 'relative' }}>
                                    {/* <DeviceTree readOnly height={MasterLayout.bodyHeight - 140}
                                /> */}
                                </div>
                            }

                            onBodyWidthChanged={() => MasterLayout.onResizeEvent.forEach(x => x.caller?.forceUpdate())}
                        />
                    }

                    {this.state.footerHeight > 0 &&
                        <div id='footer' style={{
                            display: 'flex', alignItems: 'center',
                            background: window.config?.THEME?.color1,
                            width: '100%', //position: 'fixed', bottom: '100vh',
                            height: px(this.state.footerHeight)
                        }}>
                            <Footer onUserIconClick={this.onUserIconClick} height={this.state.footerHeight} />
                        </div>
                    }
                </div>
                <UniPopup title='کاربر' commands={[]}
                    visible={this.state.userPopupVisible}
                    onClose={closeUserPopup}
                    onShown={e => this.setState({ imageKeyCounter: this.state.imageKeyCounter + 1 })}
                >
                    <UserInfo user={window.user} width={isMobile ? '100vw' : '800px'}
                        sid={'user_ppp' + this.state.imageKeyCounter}
                        onSaved={closeUserPopup} onCancel={closeUserPopup}
                    />
                </UniPopup>
            </>
        );
    }
}

export default MasterLayout;