import React, { Component } from 'react';
import { Button, SpeedDialAction } from 'devextreme-react';
import { isMobile } from 'react-device-detect';
import MasterLayout from './MasterLayout';
//import DeviceTree from '../device/DeviceTree';

class SideDivider extends Component {
    ww = isMobile ? VisualViewport.availWidth : window.innerWidth;
    hh = isMobile ? VisualViewport.availHeight : window.innerHeight;
    static innerBody = <div></div>;
    static innerBodyRef = undefined;

    state = {
        collapsed: this.props.collapsed ?? false,
        farCollapsed: this.props.farCollapsed !== false,
    };

    toggleCollapsing = whenDone => {
        this.setState({ collapsed: !this.state.collapsed }, () => {
            //this.props.onCollapsed?.(this.state.collapsed);
            this.props.onBodyWidthChanged?.();
            whenDone?.(this.state.collapsed);
        });
    }

    toggleFarCollapsing = () => {
        this.setState({ farCollapsed: !this.state.farCollapsed }, this.props.onBodyWidthChanged);
    }

    componentDidMount() {
        if (this.props.toggleBy)
            this.props.toggleBy.onClick = e => this.toggleCollapsing();
    }

    componentDidUpdate() {
    }

    render() {
        SideDivider.bodyWidth = (this.ww - (this.state.collapsed ? this.props.minWidth : this.props.maxWidth)
            - (this.props.noFar === true ? 0 : this.state.farCollapsed ? this.props.minFarWidth : this.props.maxFarWidth));

        if (this.props.toggleBy)
            this.props.toggleBy.onClick = e => this.toggleCollapsing();

        return (
            <div id='side_divider_container'
                style={{
                    display: 'flex',
                    height: this.props.height > 0 ? this.props.height + 'px' : (this.hh - 120) + 'px',
                    direction: 'rtl',
                }}>
                <div style={{
                    width: (this.state.collapsed ? this.props.minWidth : this.props.maxWidth) + 'px',
                    transition: '1s',
                    background: window.config?.THEME?.color1,
                }}
                >
                    {/* <div>
                        <Button icon='menu' width={this.props.minWidth} onClick={this.toggleCollapsing}
                            stylingMode='text'
                        />
                    </div> 
                    */}
                    <div>
                        {this.props.side}
                    </div>
                </div>
                <div id={'side_divider_body'} key={'side_divider_body' + this.props.bodyKey}
                    ref={el => SideDivider.innerBodyRef = el}
                    style={{
                        width: isMobile ? '100%' : SideDivider.bodyWidth, // = (window.innerWidth - (this.state.collapsed ? this.props.minWidth : this.props.maxWidth)
                        //- (this.state.farCollapsed ? this.props.minFarWidth : this.props.maxFarWidth)) + 'px',
                        transition: '1s',
                        overflow: 'auto',
                        //background: 'khaki',
                    }}
                >
                    {SideDivider.innerBody ?? this.props.body}
                    {/* {this.props.body} */}
                </div>
                {this.props.noFar !== true &&
                    <div style={{
                        width: (this.state.farCollapsed ? this.props.minFarWidth : this.props.maxFarWidth) + 'px',
                        transition: '1s',
                        //background: 'yellowgreen',
                        overflow: 'hidden',
                    }}
                    >
                        {/* <div>
                        <Button icon='menu' width={this.props.minFarWidth} onClick={this.toggleFarCollapsing}
                            stylingMode='text'
                        />
                    </div> */}
                        <div id='side_divider_far_side'>
                            {this.props.farSide}
                        </div>
                    </div>
                }
                {this.props.noFar !== true &&
                    <SpeedDialAction icon={this.state.farCollapsed ? 'chevronright' : 'chevronleft'}
                        hint='تجهیزات' onClick={e => this.toggleFarCollapsing()} //label='تجهیزات'
                        position={{ my: 'left top', at: 'left top', of: '#side_divider_far_side', offset: '10 5' }}
                    />
                }
            </div>
        );
    }
}

export default SideDivider;