import React, { Component } from 'react';
import { Button, CheckBox, DataGrid, Form } from 'devextreme-react';
//import Button from '../utils/creative-gadgets/ButtonWithEffect';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { ButtonOptions, EmptyItem, GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import UniPopup from '../UniPopup.js';
//import ClientCaptcha from 'react-client-captcha';
import { apiGet, apiGetP, apiPost, apiPostNoBody, apiPostP, setCookie } from '../../GanjiTools.js';
import Captcha from './Captcha.js';
import { isMobile } from 'react-device-detect';

const modes = [
    'login', 'new_psw'
];

class Logon extends Component {
    state = {
        mode: 'login',
        userName: '',
        psw: '',
        psw2: '',
        counter: 1,
        message: ' ',
        resetPswPopupVisible: false,
        captchaKeyCounter: 1,
        needsCaptcha: false,
        enteredCaptcha: '',
    };

    componentDidMount() {
        this.checkConnectionP()
            .catch(() => this.setState({ message: 'سرور در دسترس نیست' }))
            .then();
        // apiGet("/api/logon/pswRules", res => {
        //     this.pswRules = res.message;
        //     this.pswRegEx = res.regex;
        // });
    }

    onSuccessLogon = user => {
        window.user = user;
        setCookie("hurly_userid", user?.id > 0 ? user.id : '');
        setCookie("hurly_logonkey", user?.logonkey ?? '');
        setCookie("hurly_user_fullname", user?.fullname ?? '');

        this.props.onSuccessLogon?.(user);
    }

    checkConnectionP = () => new Promise((resolve, reject) => {
        apiGetP('/api/logon/checkConnection')
            .then(res => {
                let { resp } = res ?? { resp: 'no_response' };
                if (resp === 'ok') {
                    resolve?.();
                    return;
                }
                reject?.();
            }).catch(err => reject?.());
    });

    onLogon = () => {
        /*-------------
        // if (this.needsCaptcha && this.state.enteredCaptcha?.toLowerCase() !== this.captchaCode.toLowerCase()) {
        //     this.setState({ message: 'متن کپچا درست نیست', counter: this.state.counter + 1 });
        //     return;
        // }
        --------------*/

        this.checkConnectionP()
            .catch(() => this.setState({ message: 'سرور در دسترس نیست' }))
            .then(() => {
                apiPostP('/api/logon/logon', {
                    userName: this.state.userName,
                    password: this.state.psw,
                    captcha: this.state.needsCaptcha ? this.captchaComponent?.getEnteredCode?.() : ''
                }).then(res => {
                    res ??= { error: 'No response.' };
                    console.log("logon res = ", res); //////////////-------
                    this.state.needsCaptcha = res.needsCaptcha;

                    if (res.error?.length > 0) {
                        this.setState({
                            captchaKeyCounter: this.state.captchaKeyCounter + 1,
                            message: res.error, mode: 'login', psw: ''
                        });
                        return;
                    }

                    if (res.change_password) {
                        window.user = { id: res.id, logonkey: res.logonkey };
                        this.setState({ mode: 'new_psw', message: 'رمز عبور باید عوض شود' });
                        return;
                    }

                    if (res.user?.id > 0)
                        this.onSuccessLogon(res?.user);

                }).catch(cerr => this.setState({ message: 'ورود میسر نشد' })
                );
            });
    }

    onGuestLogonP = () => new Promise((resolve, reject) => {
        if (!this.props.allowGuestLogon) {
            reject?.('کاربر مهمان مجاز نیست');
            return;
        }

        this.setState({
            userName: 'guest',
            password: '',
            captcha: this.state.needsCaptcha ? this.captchaComponent?.getEnteredCode?.() : ''
        }, () => {
            this.onLogon();
            resolve?.();
        });
    });

    onNewPassword = () => {
        if (this.state.psw !== this.state.psw2) {
            this.setState({ message: 'رمز عبور و تکرار آن باید یکی باشند' });
            return;
        }

        console.log(this.pswRegEx);

        /*--------------
        if (new RegExp(this.pswRegEx).test(this.state.psw) !== true) {
            this.setState({ message: this.pswRules });
            return;
        }
        ----------------*/

        apiPost('/api/logon/new_psw',
            {
                userName: this.state.userName,
                password: this.state.psw2,
                captcha: this.state.needsCaptcha ? this.captchaComponent?.getEnteredCode?.() : ''
            },
            res => {
                if (res.error?.length > 0)
                    this.setState({ message: res.error, needsCaptcha: res.needsCaptcha });
                else
                    this.onSuccessLogon(res?.user);
            },
            err => console.log(err)
        );
    }

    onForgotPassword = () => {
        this.setState({ resetPswPopupVisible: true });
        /*-------
        // return;
    
        // if (this.needsCaptcha && (!(this.state.enteredCaptcha?.length > 0)
        //     || this.state.enteredCaptcha?.toLowerCase() !== this.captchaCode?.toLowerCase())) {
        //     this.setState({ message: 'متن کپچا درست نیست', counter: this.state.counter + 1 });
        //     return;
        // }
        // if (!(this.state.userName?.length > 0)) {
        //     this.setState({ message: 'نام کاربری را وارد کنید', counter: this.state.counter + 1 });
        //     return;
        // }
    
        // apiPost('/api/logon/resetPassword',
        //     { userName: this.state.userName, password: this.state.psw },
        //     res => {
        //         if (res.error?.length > 0)
        //             this.setState({ message: res.error });
        //         else
        //             this.setState({ message: "رمز عبور جدید به نشانی شما ایمیل شد" });
    
        //         if (res.newpsw?.length > 0)
        //             console.log('new password= ', res.newpsw);
        //     },
        //     err => console.log(err)
        // );
        -------*/
    }

    onResetPassword = () => {
        this.setState({ message: ' ' }, () =>
            apiPost('/api/logon/resetPassword',
                {
                    userName: this.state.userName,
                    nationalno: this.state.nationalno,
                    mobile: this.state.mobile,
                    captcha: this.captchaForResetPassword?.getEnteredCode?.()
                },
                res => {
                    if (res.error?.length > 0)
                        this.setState({ message: res.error });
                    else
                        this.setState({ message: "رمز عبور جدید به نشانی شما ایمیل شد" });

                    if (res.newpsw?.length > 0) //////////////////////////////////
                        console.log('new password= ', res.newpsw);
                },
                err => console.log(err)
            ));
    }

    render() {
        let needsCaptcha = this.state.needsCaptcha === true;

        return (
            <div style={{
                display: 'block', margin: 'auto', width: isMobile ? 'auto' : '30rem',
                padding: isMobile ? '1rem' : '3rem', backgroundColor: 'whitesmoke',
                marginTop: isMobile ? '1rem' : '6rem', borderRadius: isMobile ? '0.5rem' : '1rem'
            }}>
                <UniPopup
                    visible={this.state.resetPswPopupVisible} onClose={e => this.setState({ resetPswPopupVisible: false })}
                    okText='بازنشانی پسورد' cancelText='انصراف' title='بازنشانی رمز عبور'
                    onOk={st => this.onResetPassword()}
                >
                    <Form rtlEnabled={true} colCount={12}
                    >
                        <SimpleItem colSpan={12}>
                            <Label>
                                کد پرسنلی:
                            </Label>
                            <TextBox
                                //----- onValueChange={e => this.setState({ message: ' ' })}
                                value={this.state.userName} onValueChanged={v => this.setState({ userName: v.value })}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={12}>
                            <Label>
                                کد ملی:
                            </Label>
                            <TextBox
                                //------- onValueChange={e => this.setState({ message: ' ' })}
                                value={this.state.nationalno} onValueChanged={v => this.setState({ nationalno: v.value })}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={12}>
                            <Label>
                                شماره موبایل:
                            </Label>
                            <TextBox
                                //------- onValueChange={e => this.setState({ message: ' ' })}
                                value={this.state.mobile} onValueChanged={v => this.setState({ mobile: v.value })}
                            />
                        </SimpleItem>
                        {this.state.resetPswPopupVisible &&
                            <SimpleItem colSpan={12}>
                                <Captcha key={'caprsp_' + this.state.captchaKeyCounter + '_' + this.state.resetPswPopupVisible}
                                    ref={el => this.captchaForResetPassword = el} sid='rsp'
                                    userName={'_reset_password_' + this.state.userName?.toLocaleUpperCase()}
                                />
                            </SimpleItem>
                        }
                    </Form>
                </UniPopup>

                <Form rtlEnabled={true} colCount={12}
                >
                    <SimpleItem colSpan={12}>
                        <Label>
                            نام کاربری:
                        </Label>
                        <TextBox readOnly={this.state.mode === 'new_psw'}
                            //---------- onValueChange={e => this.setState({ message: ' ' })}
                            value={this.state.userName} onValueChanged={v => this.setState({ userName: v.value })}
                        >
                        </TextBox>
                    </SimpleItem>
                    <SimpleItem colSpan={12}>
                        <Label>
                            رمز عبور:
                        </Label>
                        <TextBox mode='password'
                            //---------- onValueChange={e => this.setState({ message: ' ' })}
                            value={this.state.psw} onValueChanged={v => this.setState({ psw: v.value })}
                        />
                    </SimpleItem>
                    {this.state.mode === 'new_psw' &&
                        <SimpleItem colSpan={12}>
                            <Label>
                                تکرار رمز عبور:
                            </Label>
                            <TextBox mode='password'
                                //----------- onValueChange={e => this.setState({ message: ' ' })}
                                value={this.state.psw2} onValueChanged={v => this.setState({ psw2: v.value })}
                            />
                        </SimpleItem>
                    }
                    {/*------- <SimpleItem colSpan={12}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <span style={{ padding: '1rem' }}>
                                <Button text='ورود' width='10rem' height='50px' />
                            </span>
                            <TextBox width='10rem' onValueChanged={v => this.setState({ enteredCaptcha: v })} />
                            <ClientCaptcha captchaCode={code => { this.captchaCode = code }} />
                        </div>
                    </SimpleItem> -------*/}

                    {false && needsCaptcha &&
                        <SimpleItem colSpan={isMobile ? 12 : 6}>
                            <TextBox placeholder='کپچا' onValueChanged={v => this.setState({ enteredCaptcha: v.value })} />
                        </SimpleItem>
                    }
                    {needsCaptcha &&
                        <SimpleItem colSpan={12}>
                            <Captcha key={'captch_' + this.state.captchaKeyCounter}
                                sid='logon'
                                ref={el => this.captchaComponent = el}
                                userName={this.state.userName}
                            />
                        </SimpleItem>
                    }
                    {/* 
                    {false && needsCaptcha &&
                        <SimpleItem colSpan={6}>
                            <ClientCaptcha key={'captcha' + this.state.counter} //----height={50}
                                captchaCode={code => this.captchaCode = code} />
                        </SimpleItem>
                    } */}

                    <SimpleItem colSpan={12}>
                        <p style={{
                            color: 'red', display: this.state.message?.length > 0 ? 'block' : 'none',
                            minHeight: '2rem',
                        }}>
                            {this.state.message ?? ' '}
                        </p>
                    </SimpleItem>

                    {this.state.mode === 'new_psw' ?
                        <SimpleItem colSpan={isMobile ? 12 : 6}>
                            <Button icon='check' text='ثبت' width={isMobile ? '100%' : '95%'} //-------height='50px'
                                onClick={this.onNewPassword}
                            />
                        </SimpleItem>
                        :
                        <SimpleItem colSpan={isMobile ? 12 : 6}>
                            <Button text='ورود' width={isMobile ? '100%' : '95%'} //-------height='50px'
                                onClick={this.onLogon}
                            />
                        </SimpleItem>
                    }
                    {true && //this.state.userName?.length > 0 &&
                        <SimpleItem colSpan={isMobile ? 12 : 6}>
                            <Button text='فراموشی رمز عبور' width={isMobile ? '100%' : '95%'} //-------height='50px'
                                onClick={this.onForgotPassword}
                            />
                        </SimpleItem>
                    }
                    {this.props.allowGuestLogon &&
                        <SimpleItem colSpan={12}>
                            <Button text='ورود مهمان' width={isMobile ? '100%' : '97.5%'} //-------height='50px'
                                onClick={() => this.onGuestLogonP().then().catch(window.notif)}
                            />
                        </SimpleItem>
                    }

                    <SimpleItem colSpan={12}>
                        <Button text='ورود ادمین' width={isMobile ? '100%' : '97.5%'} //-------height='50px'
                            onClick={() => this.onSuccessLogon({ id: 1, fullname: 'مدیر' })}
                        />
                    </SimpleItem>

                </Form>
            </div >
        );
    }
}

export default Logon;