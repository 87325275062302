import React, { Component } from 'react';
import { UniGrid } from './UniGrid';
import { apiGet, toLowerKeys } from '../GanjiTools';
import { Button } from 'devextreme-react';
import MinimalButton from './MinimalButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class UniMetaGrid extends Component {

    state = { rows: [] }

    uniMetaColumns = [
        { name: 'summary', visible: false },
        { name: 'id', visible: false },
        { name: 'tablename', visible: false },
        { name: 'columnname', caption: 'نام ستون', readOnly: true },
        { name: 'columnorder', format: 'number', caption: 'ترتیب', keyFilter: 'pnum' },
        { name: 'isreadonly', format: 'bool', caption: 'فقط خواندنی' },
        { name: 'isparent', format: 'bool', caption: 'ریشه' },
        { name: 'isgroup', format: 'bool', caption: 'گروه' },
        { name: 'ishidden', format: 'bool', caption: 'پنهان' },
        { name: 'isimage', format: 'bool', caption: 'تصویر' },
        { name: 'datatypeformat', caption: 'فرمت' },
        { name: 'filtertype', caption: 'نوع فیلتر' },
        { name: 'caption_fa', caption: 'عنوان فارسی' },
        { name: 'caption_en', caption: 'عنوان انگلیسی' },
        { name: 'caption_ar', caption: 'عنوان عربی' },
        { name: 'attrib', caption: 'خصوصیات' },
        { name: 'columnwidth', format: 'number', caption: 'عرض' },
    ];

    componentDidMount() {
        if (this.props.sourceTable?.length > 0)
            apiGet(`/api/uni/metaGrid/${this.props.sourceTable}`,
                resp => { this.setState({ rows: toLowerKeys(resp.rows) }) },
                err => console.log('Could not fetch MetaUniGrid for table ', this.props.sourceTable, ' | error= ', err)
            );
        else if (this.props.sourceSid?.length > 0)
            apiGet(`/api/uni/metaUrlGrid/${this.props.sourceSid}/${JSON.stringify(this.props.sourceColNames)}`,
                resp => { this.setState({ rows: toLowerKeys(resp.rows) }) },
                err => console.log('Could not fetch MetaUniGrid for sid ', this.props.sourceSid, ' | error= ', err)
            );
    }

    render() { // Karestan@2023@
        //console.log('rows=', this.state.rows);
        //console.log('cols=', this.state.columns);
        return (
            <>
                <div style={{ height: '1rem' }}>&nbsp;</div>
                <UniGrid key={this.state.rows?.length} rows={this.state.rows}
                    columns={this.uniMetaColumns} //modifyFields={this.uniMetaColumns}
                    onSaving={e => console.log('onSaving ', e)} saveToTable='tbl_UniGridSetting' noMetaGrid={true}
                    title={this.props.title?.length > 0 ? `اطلاعات ستونهای "${this.props.title}"` : 'اطلاعات ستونها'}
                    onSaved={() => this.props.onSaved(this.state.rows)} allowDeleting={false}
                    allowAdding={this.props.sourceSid?.length > 0}
                    allowEditing={true} //////////////////
                    editingMode='row' putTitleBoxInToolbar
                    putItemsBeforeTitle={
                        <MinimalButton onClick={this.props.onCloseMe} style={{ marginLeft: '0.5rem' }}>
                            <FontAwesomeIcon icon='square-xmark' fontSize='25px' />
                        </MinimalButton>
                    }
                />
            </>
        );
    }
}

export default UniMetaGrid;